export const UPDATE_ACTIVE_CREWS_CREW_MOVEMENTS_TABLE_SORT = 'UPDATE_ACTIVE_CREWS_CREW_MOVEMENTS_TABLE_SORT';
export const UPDATE_DRIVER_MOVEMENTS_TABLE_SORT = 'UPDATE_DRIVER_MOVEMENTS_TABLE_SORT';
export const SET_ACTIVE_CREWS_CREW_MOVEMENTS_DEFAULT_SORT = 'SET_ACTIVE_CREWS_CREW_MOVEMENTS_DEFAULT_SORT';
export const SET_DRIVER_MOVEMENTS_DEFAULT_SORT = 'SET_DRIVER_MOVEMENTS_DEFAULT_SORT';
export const UPDATE_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_TABLE_SORT = 'UPDATE_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_TABLE_SORT';
export const SET_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_DEFAULT_SORT = 'SET_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_DEFAULT_SORT';
export const UPDATE_ACTIVE_CREWS_DRIVER_METRICS_TABLE_SORT = 'UPDATE_ACTIVE_CREWS_DRIVER_METRICS_TABLE_SORT';
export const SET_ACTIVE_CREWS_DRIVER_METRICS_DEFAULT_SORT = 'SET_ACTIVE_CREWS_DRIVER_METRICS_DEFAULT_SORT';
export const ADD_DRAFT_FILTER = 'ADD_DRAFT_FILTER';
export const REMOVE_DRAFT_FILTER = 'REMOVE_DRAFT_FILTER';
export const SET_FINAL_FILTERS = 'SET_FINAL_FILTERS';
export const SET_DRAFT_FILTERS = 'SET_DRAFT_FILTERS';
export const SET_SELECTED_CREW_LEADER = 'SET_SELECTED_CREW_LEADER';
export const CLEAR_SELECTED_CREW_LEADER = 'CLEAR_SELECTED_CREW_LEADER';
