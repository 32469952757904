import { combineReducers } from 'redux';
import domain from './domain/reducer';
import user from './user/reducer';
import activeCrews from './activeCrews/reducer';

const rootReducer = combineReducers({
    activeCrews,
    user,
    domain,
});

export default rootReducer;
