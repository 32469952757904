import {
    generateCrewLeaderTableCell,
    generateGenericCellData,
    generateIssueFlags,
    generateTimeCellData,
} from '../../../reusableComponents/CellTransformers/cellTransformers';
import { CommonTableHeaderData } from '../../../global';

export const crewMovementsCurrentCycleTableHeaders: CommonTableHeaderData[] = [
    {
        id: 'crew-leader-header',
        key: 'activeLeader',
        displayName: 'Crew Leader',
        transform: generateCrewLeaderTableCell,
        isSortable: false,
    },
    {
        id: 'lot-origin-header',
        key: 'lotOrigin',
        displayName: 'Lot Origin',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'longest-delivery-header',
        key: 'longestDelivery',
        displayName: 'Longest Delivery',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'longest-return-header',
        key: 'longestReturn',
        displayName: 'Longest Return',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'issues-flagged-header',
        key: 'issuesFlagged',
        displayName: 'Issues Flagged',
        transform: generateIssueFlags,
        isSortable: true,
    },
];

export const crewMovementsDailySummaryTableHeaders: CommonTableHeaderData[] = [
    {
        id: 'bulk-lost-time-header',
        key: 'bulkLostTime',
        displayName: 'Bulk Lost Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'bulk-lost-lag-time-header',
        key: 'bulkLostLagTime',
        displayName: 'Bulk Lost Lag Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'total-mdps-header',
        key: 'totalMDPs',
        displayName: 'Total # of MDPs',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'total-ltls-header',
        key: 'totalLTLs',
        displayName: 'Total # of LTLs',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'total-moves-header',
        key: 'totalMoves',
        displayName: 'Total Moves',
        transform: generateGenericCellData,
        isSortable: true,
    },

    {
        id: 'moves-per-hour-header',
        key: 'movesPerHour',
        displayName: 'Moves Per Hour',
        transform: generateGenericCellData,
        isSortable: true,
    },
];
