import { UPDATE_DOMAIN, SET_LOADING } from './constants';

export default function domain(
    state: any = {
        activeCrews: 0,
        crewLeadersFilterArr: [],
        crewMovements: [],
        lotResultsSummary: [],
        crewLeaderCrewMoves: null,
        saleLanes: [],
        loading: false,
        totalDrivers: {},
        driverMetrics: {},
    },
    action: any,
) {
    switch (action.type) {
        case UPDATE_DOMAIN:
            return {
                ...action.data,
                loading: false,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        default:
            return state;
    }
}
