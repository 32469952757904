import React from 'react';
// @ts-ignore
import filterImg from '../../assets/filter.svg';

interface OuterProps {
    id: string;
    openFilter: () => any;
}

const FilterIcon: React.FC<OuterProps> = props => {
    const { openFilter, id } = props;
    return <img style={{ cursor: 'pointer' }} id={id} alt="" src={filterImg} onClick={openFilter} />;
};

export default FilterIcon;
