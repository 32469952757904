import React from 'react';
import BulkMovesTab from './BulkMovesTab';
import Tabs from '@material-ui/core/Tabs';
import { BulkMoveTabLabelData } from '../../global';
import withStyles from '@material-ui/core/styles/withStyles';

interface BulkMovesTabContainerProps {
    tabs: BulkMoveTabLabelData[];
    selectedTabIndex: number;
    onTabChange: (event: React.ChangeEvent<{}>, value: number) => void;
}

const StyledTabContainer = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        background: '#FFFFFF',
        width: '100%',
        borderBottom: 'solid 1px #babcbe',
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    indicator: {
        height: '.4rem',
        backgroundColor: '#003468',
    },
})(Tabs);

const BulkMovesTabContainer: React.FC<BulkMovesTabContainerProps> = props => {
    const { tabs, onTabChange, selectedTabIndex } = props;
    return (
        <StyledTabContainer value={selectedTabIndex}>
            {tabs.map((tab, index) => {
                return <BulkMovesTab onTabChange={onTabChange} key={index} data={tab} />;
            })}
        </StyledTabContainer>
    );
};

export default BulkMovesTabContainer;
