import {
    generateGenericCellData,
    generateDateCellData,
    generatePathCellData,
    generateBenchmarkedTime,
} from '../../../reusableComponents/CellTransformers/cellTransformers';
import { CommonTableHeaderData } from '../../../global';

const assignedTimeHeader = {
    id: 'assigned-time-header',
    key: 'assignedTime',
    displayName: 'Assigned',
    transform: generateDateCellData,
    isSortable: true,
};

const workOrderHeader = {
    id: 'work-order-header',
    key: 'workOrder',
    displayName: 'WO#',
    transform: generateGenericCellData,
    isSortable: false,
};

const returnPathHeader = {
    id: 'return-path-header',
    key: 'returnPath',
    displayName: 'Path',
    transform: generatePathCellData,
    isSortable: false,
};

const crewLeaderHeader = {
    id: 'crew-leader-header',
    key: 'crewLeader',
    displayName: 'Crew Leader',
    transform: generateGenericCellData,
    isSortable: false,
};

const driverHeader = {
    id: 'driver-header',
    key: 'driver',
    displayName: 'Driver',
    transform: generateGenericCellData,
    isSortable: false,
};

const vinHeader = {
    id: 'vin-header',
    key: 'vin',
    displayName: 'VIN',
    transform: generateGenericCellData,
    isSortable: false,
};

const statusHeader = {
    id: 'status-header',
    key: 'status',
    displayName: 'Status',
    transform: generateGenericCellData,
    isSortable: false,
};

const moveTimeHeader = {
    id: 'move-time-header',
    key: 'moveTime',
    displayName: 'Move Time',
    transform: generateBenchmarkedTime,
    isSortable: true,
};

const slowLostTimeHeader = {
    id: 'slow-lost-time-header',
    key: 'slowLostTime',
    displayName: 'Slow Lost Time',
    transform: generateBenchmarkedTime,
    isSortable: true,
};

const lagTimeHeader = {
    id: 'lag-time-header',
    key: 'lagTime',
    displayName: 'Lag Time',
    transform: generateBenchmarkedTime,
    isSortable: true,
};

const lagLostTimeHeader = {
    id: 'lag-lost-time-header',
    key: 'lagLostTime',
    displayName: 'Lag Lost Time',
    transform: generateBenchmarkedTime,
    isSortable: true,
};

export const driverMovementTableHeaders: CommonTableHeaderData[] = [
    assignedTimeHeader,
    workOrderHeader,
    returnPathHeader,
    driverHeader,
    vinHeader,
    statusHeader,
    moveTimeHeader,
    slowLostTimeHeader,
    lagTimeHeader,
    lagLostTimeHeader,
];

export const commonMovementTableHeaders: CommonTableHeaderData[] = [
    assignedTimeHeader,
    workOrderHeader,
    returnPathHeader,
    crewLeaderHeader,
    driverHeader,
    vinHeader,
    moveTimeHeader,
    slowLostTimeHeader,
    lagTimeHeader,
    lagLostTimeHeader,
];
