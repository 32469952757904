let bulkMovesTabActiveCrews = {
    id: 'active-crews-tab-button',
    tabValue: 0,
    label: 'Active Crews',
    value: '0',
};

let bulkMovesTabActiveDrivers = {
    id: 'active-drivers-tab-button',
    tabValue: 0,
    label: 'Active Drivers',
    value: '0',
};

let bulkMovesTabTotalBulkMoves = {
    id: 'total-bulk-moves-tab-button',
    tabValue: 1,
    label: 'Total Bulk Moves',
    value: '0',
};

let bulkMovesTabLagPercentage = {
    id: 'lag-percentage-tab-button',
    tabValue: 2,
    label: 'Lag Percentage',
    value: '0%',
};

let bulkMovesTabSlowMovePercentage = {
    id: 'slow-move-percentage-tab-button',
    tabValue: 3,
    label: 'Slow Move Percentage',
    value: '0%',
};

let bulkMovesTabBulkLostTime = {
    id: 'bulk-lost-time-tab-button',
    tabValue: 4,
    label: 'Bulk Lost Time',
    value: '0.0',
};

let bulkMovesTabBulkLostLagTime = {
    id: 'bulk-lost-lag-time-tab-button',
    tabValue: 5,
    label: 'Bulk Lost Lag Time',
    value: '0.0',
};

const bulkMovesTabHeaders = [
    bulkMovesTabActiveCrews,
    bulkMovesTabTotalBulkMoves,
    bulkMovesTabLagPercentage,
    bulkMovesTabSlowMovePercentage,
    bulkMovesTabBulkLostTime,
    bulkMovesTabBulkLostLagTime,
];

const bulkMovesTabHeadersDrivers = [
    bulkMovesTabActiveDrivers,
    bulkMovesTabTotalBulkMoves,
    bulkMovesTabLagPercentage,
    bulkMovesTabSlowMovePercentage,
    bulkMovesTabBulkLostTime,
    bulkMovesTabBulkLostLagTime,
];

const appTabHeaders = [
    {
        id: 'bulk-moves-tab-button',
        tabValue: 0,
        label: 'Bulk Moves Productivity',
        value: '0',
    },
    {
        id: 'sale-moves-tab-button',
        tabValue: 1,
        label: 'Sale Moves Productivity',
        value: '0',
    },
    {
        id: 'enhanced-reports-tab-button',
        tabValue: 2,
        label: 'Enhanced Reports',
        value: '0%',
    },
];

const appTabHeadersProd = [
    {
        id: 'bulk-moves-tab-button',
        tabValue: 0,
        label: 'Bulk Moves Productivity',
        value: '0',
    },
];

export { bulkMovesTabHeaders, bulkMovesTabHeadersDrivers, appTabHeaders, appTabHeadersProd };
