import React from 'react';
import { Checkbox, createStyles } from '@material-ui/core';
import styles from './DialogCheckbox.module.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';

interface OuterProps {
    id?: string;
    label: string;
    onCheck: () => void;
    onUnCheck: () => void;
    isChecked: boolean;
}

const useStyles = makeStyles(
    createStyles({
        checkbox: {
            color: 'black',
        },
    }),
);

const StyledCheckbox = withStyles({
    root: {
        color: '#003468',
        '&$checked': {
            color: '#003468',
        },
    },
    checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const DialogCheckbox: React.FC<OuterProps> = props => {
    const { label, onCheck, onUnCheck, isChecked, id } = props;
    const classStyles = useStyles();

    const handleChange = (event: any) => {
        !event.target.checked ? onCheck() : onUnCheck();
    };

    return (
        <div className={styles.dialogContainer}>
            <StyledCheckbox
                classes={{ root: classStyles.checkbox }}
                id={id ? id : undefined}
                className="checkbox"
                checked={isChecked}
                onChange={handleChange}
                color="primary"
            />
            <div className={styles.label} onClick={isChecked ? onCheck : onUnCheck}>
                {label}
            </div>
        </div>
    );
};

export default DialogCheckbox;
