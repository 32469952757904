import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { wsJoinRoom, wsLeaveRoom } from '../../redux/socket/action';
import { AuctionDetails, UserProfile, SocketEvent, TotalDrivers } from '../../global';
import SaleAlertBar from '../../reusableComponents/SaleAlertBar/SaleAlertBar';
import SaleMovesTable from '../../reusableComponents/SaleMovesTable/SaleMovesTable';
import smStyles from '../../reusableComponents/SaleMovesTable/SaleMovesProductivity.module.css';
import { Error } from '@material-ui/icons';
import AuctionDropdown from '../../reusableComponents/AuctionDropdown/AuctionDropdown';
import { isEmpty } from 'lodash';

interface SaleMovesState {
    selectedTabIndex: number;
    loading: boolean;
}
interface OuterProps {
    userProfile: UserProfile;
    auctionList: AuctionDetails[];
    selectedAuction: string;
    wsJoinRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    wsLeaveRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    totalDrivers: TotalDrivers;
}

class SaleMovesProductivity extends React.Component<OuterProps, SaleMovesState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            selectedTabIndex: 1,
            loading: false,
        };
    }
    /**
     *In this lifecycle function we check for an auth token in local storage and if we have one we
     *verify the token. If not we initiate OAM login. Then we setup the socket connection for the
     *current auction.
     *
     * @returns {Promise<void>}
     * @memberof App
     */
    public async componentDidMount(): Promise<void> {
        const { userProfile, selectedAuction, wsJoinRoomFn } = this.props;
        wsJoinRoomFn({
            auctionCode: selectedAuction,
            filter: '',
            user: userProfile.UserName,
            mode: 'sale',
        });
    }

    public componentWillUnmount(): void {
        const { wsLeaveRoomFn, userProfile, selectedAuction } = this.props;
        wsLeaveRoomFn({
            auctionCode: selectedAuction,
            user: userProfile.UserName,
            filter: '',
            mode: 'sale',
        });
    }

    private getTotalDrivers = (object: TotalDrivers) => {
        if (isEmpty(object)) {
            return {
                checkedInTempLabor: '--',
                assignedDrivers: '--',
                activeDrivers: '--',
                optimalDrivers: '--',
            };
        } else {
            return object;
        }
    };

    /**
     *In this lifecycle function we leave the current room on the socket connection
     *if the user is logged in
     *
     * @memberof App
     */
    public render() {
        const totalDrivers = this.getTotalDrivers(this.props.totalDrivers);

        return (
            <div id="sale-moves-productivity" className={smStyles.smpContainer}>
                <div className={smStyles.innerFixed}>
                    <SaleAlertBar />
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1em 0' }}>
                        <h1 className={smStyles.saleMovesTitle} id="sale-moves-productivity-title">
                            Lane Activity (8/21/19)
                        </h1>
                        <AuctionDropdown />
                    </div>
                    <div className={smStyles.saleMovesHeader}>
                        <div className={smStyles.totalDriversContainer} id="total-drivers">
                            <h3>Total Drivers</h3>
                            <div className={smStyles.totalDriversGrid}>
                                <div className={smStyles.metric}>
                                    <p>
                                        <span id="sale-moves-checked-in-value" className={smStyles.metricValue}>
                                            {/*{totalDrivers.checkedInTempLabor}*/}
                                            --
                                        </span>
                                        <span id="sale-moves-checked-in-title">
                                            Checked-in
                                            <br />
                                            Temp Labor
                                        </span>
                                    </p>
                                </div>
                                <div className={smStyles.metric}>
                                    <p>
                                        <span id="sale-moves-assigned-value" className={smStyles.metricValue}>
                                            {totalDrivers.assignedDrivers}
                                        </span>
                                        <span id="sale-moves-assigned-title">
                                            Assigned
                                            <br />
                                            to Lane
                                        </span>
                                    </p>
                                </div>
                                <div className={smStyles.metric}>
                                    <p>
                                        <span id="sale-moves-active-value" className={smStyles.metricValue}>
                                            {totalDrivers.activeDrivers}
                                        </span>
                                        <span id="sale-moves-active-title">Active</span>
                                    </p>
                                </div>
                                <div className={smStyles.metric}>
                                    <p>
                                        <span id="sale-moves-optimal-value" className={smStyles.metricValue}>
                                            {/*{totalDrivers.optimalDrivers}*/}
                                            --
                                        </span>
                                        <span id="sale-moves-optimal-title">Optimal</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={smStyles.saleMovesFilterContainer}>
                            <h3>
                                <input type="checkbox" /> All Lanes
                            </h3>
                            <div className={smStyles.saleMovesFilterGrid}>
                                <div className={smStyles.saleMovesFilterType}>
                                    <h4>By Pace</h4>
                                    <ul>
                                        <li>
                                            <input type="checkbox" /> Fast (4)
                                        </li>
                                        <li>
                                            <input type="checkbox" /> Good (10)
                                        </li>
                                        <li>
                                            <input type="checkbox" /> Slow (10)
                                        </li>
                                    </ul>
                                </div>
                                <div className={smStyles.saleMovesFilterType}>
                                    <h4>By Utilization</h4>
                                    <ul>
                                        <li>
                                            <input type="checkbox" /> Available Drivers (3)
                                        </li>
                                        <li className={smStyles.error}>
                                            <input type="checkbox" /> <Error fontSize="inherit" /> Needs Drivers (7)
                                        </li>
                                        <li className={smStyles.error}>
                                            <input type="checkbox" /> <Error fontSize="inherit" /> Has Walk-offs (1)
                                        </li>
                                    </ul>
                                </div>
                                <div className={smStyles.saleMovesFilterType}>
                                    <h4>By Excess Times</h4>
                                    <ul>
                                        <li>
                                            <input type="checkbox" /> High Cycle Times (5)
                                        </li>
                                        <li>
                                            <input type="checkbox" /> High Block Times (6)
                                        </li>
                                        <li>
                                            <input type="checkbox" /> High Cycle Time Deviations (4)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SaleMovesTable />
            </div>
        );
    }
}
export const mapStateToProps = (state: any) => ({
    userProfile: state.user.profile,
    auctionList: state.user.auctionList,
    selectedAuction: state.user.selectedAuction,
    totalDrivers: state.domain.totalDrivers,
});
export const mapDispatchToProps = (dispatch: any) => ({
    wsJoinRoomFn: (socketEvent: SocketEvent) => dispatch(wsJoinRoom(socketEvent)),
    wsLeaveRoomFn: (socketEvent: SocketEvent) => dispatch(wsLeaveRoom(socketEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaleMovesProductivity);
