import React from 'react';
import { CommonTableHeaderData } from '../../global';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import styles from './CommonTableBody.module.css';
import withStyles from '@material-ui/core/styles/withStyles';

interface OuterProps {
    rows: any[];
    headers: CommonTableHeaderData[];
    innerRef?: any;
    isScrollBarHidden?: boolean;
    scrollFn?: () => void;
}

const StyledTableCell = withStyles({
    root: {
        padding: '0rem',
        textAlign: 'center',
        minWidth: '12rem',
    },
})(TableCell);

const StyledTableRow = withStyles({
    root: {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
        height: '4.2rem',
        '&:nth-child(even)': {
            background: '#F3F3F3',
        },
    },
})(TableRow);

const StyledTableBodyWithoutScrollBar = withStyles({
    root: {
        display: 'block',
        overflow: 'auto',
        width: '100%',
        tableLayout: 'fixed',
        maxHeight: '35rem',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
})(TableBody);

const StyledTableBody = withStyles({
    root: {
        display: 'block',
        overflow: 'auto',
        width: '100%',
        tableLayout: 'fixed',
        maxHeight: '35rem',
    },
})(TableBody);

class WrappedComponent extends React.Component<OuterProps> {
    render() {
        const { rows, headers, innerRef, isScrollBarHidden, scrollFn } = this.props;
        return isScrollBarHidden ? (
            <StyledTableBodyWithoutScrollBar ref={innerRef && innerRef} onScroll={scrollFn && scrollFn}>
                {rows.map((row: any, rowIndex: number) => {
                    return (
                        <StyledTableRow key={rowIndex}>
                            {headers.map((header: CommonTableHeaderData, headerIndex: number) => {
                                return (
                                    <StyledTableCell key={headerIndex}>
                                        <div className={styles.tableCellData}>
                                            {header.transform ? header.transform(row[header.key]) : row[header.key]}
                                        </div>
                                    </StyledTableCell>
                                );
                            })}
                        </StyledTableRow>
                    );
                })}
            </StyledTableBodyWithoutScrollBar>
        ) : (
            <StyledTableBody ref={innerRef && innerRef} onScroll={scrollFn && scrollFn}>
                {rows.map((row: any, rowIndex: number) => {
                    return (
                        <StyledTableRow key={rowIndex}>
                            {headers.map((header: CommonTableHeaderData, headerIndex: number) => {
                                return (
                                    <StyledTableCell key={headerIndex}>
                                        <div className={styles.tableCellData}>
                                            {header.transform ? header.transform(row[header.key]) : row[header.key]}
                                        </div>
                                    </StyledTableCell>
                                );
                            })}
                        </StyledTableRow>
                    );
                })}
            </StyledTableBody>
        );
    }
}

const CommonTableBody = React.forwardRef((props: any, ref: any) => {
    return <WrappedComponent {...props} innerRef={ref} />;
});

export default CommonTableBody;
