import React from 'react';
import styles from './BulkMoveTabLabel.module.css';
import { BulkMoveTabLabelData } from '../../global';

interface BulkMoveTabLabelProps {
    data: BulkMoveTabLabelData;
}
const BulkMoveTabLabel: React.FC<BulkMoveTabLabelProps> = props => {
    const { data } = props;
    return (
        <div className={styles.bulkMoveLabelContainer}>
            <div id={`${data.id}-value`} className={styles.bulkMoveLabelValue}>
                {data.value}
            </div>
            <div id={`${data.id}-label`} className={styles.bulkMoveLabelTitle}>
                {data.label}
            </div>
        </div>
    );
};

export default BulkMoveTabLabel;
