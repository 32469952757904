import _ from 'lodash';
import {
    FETCH_USER_DATA,
    FETCH_USER_DATA_SUCCESS,
    FETCH_USER_DATA_FAILURE,
    CREATE_AUCTION_LIST,
    UPDATE_SELECTED_AUCTION,
    CREATE_CREWS_FILTER_ARR,
    UPDATE_SELECTED_DASHBOARD,
    UPDATE_BULK_FILTER_STR,
} from './constants';
import UserService from '../../services/UserService';
import { UserProfile } from '../../global';
import auctionList from '../../config/complete_auction_data.json';

declare global {
    interface Window {
        EmployeeWorkbench: any;
    }
}

export const fetchUserData = () => {
    return {
        type: FETCH_USER_DATA,
    };
};

export const fetchUserDataSuccess = (userProfile: UserProfile) => {
    return {
        type: FETCH_USER_DATA_SUCCESS,
        userProfile,
    };
};

export const fetchUserDataFailure = () => {
    return {
        type: FETCH_USER_DATA_FAILURE,
    };
};

export const createCrewsFilterArr = (crewMovements: any[]) => {
    const filteredCrewsArr = crewMovements.map(crewMovement => {
        return crewMovement.crewLeader;
    });
    return {
        type: CREATE_CREWS_FILTER_ARR,
        filteredCrewsArr,
    };
};

/**
 *This function saves this users auction list to redux for the auction dropdown
 *
 * @param {UserProfile} userProfile
 * @returns The auction list that this user has access to view
 */
export const createAuctionList = (userProfile: UserProfile) => {
    const filteredAuctions = auctionList.filter(
        e =>
            (e.AuctionCode === userProfile.HomeAuction && e.AuctionCode !== 'MAA') ||
            e.peopleSoftCode === userProfile.HomeAuction ||
            userProfile.WBAdditionalLocations.split(',').find(
                x => (x === e.AuctionCode && x !== 'MAA') || x === e.peopleSoftCode,
            ),
    );

    const sortedFilteredAuctions = _.orderBy(filteredAuctions, ['AuctionName'], ['asc']);

    return {
        type: CREATE_AUCTION_LIST,
        sortedFilteredAuctions,
    };
};

export const updateSelectedAuction = (selectedAuction: string) => {
    return {
        type: UPDATE_SELECTED_AUCTION,
        selectedAuction,
    };
};

export const updateSelectedDashboard = (selectedDashboard: string) => {
    return {
        type: UPDATE_SELECTED_DASHBOARD,
        selectedDashboard,
    };
};
export const updateBulkFilterStr = (bulkFilterStr: string) => {
    return {
        type: UPDATE_BULK_FILTER_STR,
        bulkFilterStr,
    };
};

/**
 *This function gets the user profile from the node service, saves the profile into
 *redux and then injects the workbench header on the page with the user data
 *
 * @param {string} token The jwt representing the users profile
 */
export const fetchUserDataFn = (token: string) => {
    return async (dispatch: any) => {
        dispatch(fetchUserData());
        return await UserService.getProfile(token).then(
            userProfile => {
                injectHeader(userProfile);
                dispatch(fetchUserDataSuccess(userProfile));
                dispatch(createAuctionList(userProfile));
                dispatch(updateSelectedAuction(userProfile.HomeAuction));
                return Promise.resolve();
            },
            error => {
                dispatch(fetchUserDataFailure());
                return Promise.reject(error);
            },
        );
    };
};

export const injectHeader = (userProfile: UserProfile) => {
    if (window.EmployeeWorkbench) {
        const context = window.EmployeeWorkbench.buildContext()
            .addRequiredValues(
                'ewbDiv',
                userProfile.FirstName,
                userProfile.LastName,
                userProfile.Mail,
                userProfile.WBAppList,
                'LOD',
                userProfile.HomeAuction,
                userProfile.WBAdditionalLocations,
                'ewbFooter',
            )
            .showBetaWithApplicationName(false)
            .addAnalyticsAttributes('LotOpsDashboard', 'SPA', userProfile.UserName)
            .getContext();
        window.EmployeeWorkbench.injectHeader(context);
    }
};
