import React from 'react';
import { connect } from 'react-redux';
import { CrewMovementData, CrewAssignedTimes } from '../../../global';
import styles from '../ActiveCrews.module.css';
import AlertBar from '../../../reusableComponents/AlertBar/AlertBar';
import CrewMovementTable from '../../../reusableComponents/DataTables/CrewMovementTable/CrewMovementTable';
import LotResultsSummaryTable from '../../../reusableComponents/DataTables/LotResultsSummaryTable/LotResultsSummaryTable';

interface OuterProps {
    crewMovements: CrewMovementData[];
    crewLeaderCrewMoves: { [key: string]: CrewAssignedTimes[] };
}

class CrewLeadersView extends React.Component<OuterProps, {}> {
    public constructor(props: any) {
        super(props);
    }

    private generateMovesPerHour = (data: CrewMovementData[]) => {
        var totalMovesPerHour = 0;

        data.forEach(crewMovement => {
            const movesPerHour = crewMovement.movesPerHour ? crewMovement.movesPerHour : 0;
            totalMovesPerHour = totalMovesPerHour + movesPerHour;
        });
        return (totalMovesPerHour / data.length).toFixed(1);
    };

    public render() {
        const { crewMovements } = this.props;
        let movesPerHour = this.generateMovesPerHour(crewMovements);
        return (
            <div>
                <div className={styles.crewMovementsContainer}>
                    <AlertBar />
                    <div className={styles.titleContainer}>
                        <div id="crew-movements-table-title" className={styles.tableTitle}>
                            Crew Movements{' '}
                            {!isNaN(parseFloat(movesPerHour)) && (
                                <span id="crew-movements-table-title-mph">({movesPerHour} Moves Per Hour)</span>
                            )}
                        </div>
                    </div>
                    <CrewMovementTable />
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <div id="lot-results-summary-table-title" className={styles.tableTitle}>
                            Lot Results Summary
                        </div>
                    </div>
                    <LotResultsSummaryTable tableId="lot-results-summary" />
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state: any) => ({
    crewMovements: state.domain.crewMovements,
    crewLeaderCrewMoves: state.domain.crewLeaderCrewMoves,
});

export default connect(mapStateToProps)(CrewLeadersView);
