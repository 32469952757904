import { Middleware } from 'redux';
import socketIOClient from 'socket.io-client';
import { rtrBackendUrl } from '../config/config';
import { updateDomain, setLoading } from '../redux/domain/actions';
import { DomainState } from '../global';

const socketConnection = socketIOClient.connect(rtrBackendUrl, { transports: ['websocket'] });

export const generateEventString = (auctionCode: string, mode: string) => {
    const event = `${mode}/auction/${auctionCode}`;
    return event;
};

export const processSocketData = (filter: string, data: DomainState) => {
    let filteredData: DomainState = {
        activeCrews: 0,
        activeDrivers: 0,
        totalSlowMoves: 0,
        totalLagMoves: 0,
        totalBulkMoves: 0,
        lagPercentage: 0,
        slowMovePercentage: 0,
        bulkLostTime: 0,
        bulkLostLagTime: 0,
        driverMetrics: {},
        crewMovements: [],
        crewLeaderCrewMoves: {},
        crewLeadersFilterArr: data.crewLeadersFilterArr,
        lotResultsSummary: [],
        saleLanes: data.saleLanes,
        totalDrivers: data.totalDrivers,
    };
    if (filter !== '' && filter !== '*ALL*') {
        let crewLeadersFilter = filter.split(',');

        crewLeadersFilter.forEach(crewLeader => {
            // @ts-ignore
            filteredData.driverMetrics[crewLeader] = data.driverMetrics[crewLeader];
            // @ts-ignore
            filteredData.crewLeaderCrewMoves[crewLeader] = data.crewLeaderCrewMoves[crewLeader];

            // @ts-ignore
            let driverMetricsArray = data.driverMetrics[crewLeader];

            if (driverMetricsArray) {
                // @ts-ignore
                driverMetricsArray.forEach(driver => {
                    if (driver.activeDriver.active === true) {
                        filteredData.activeDrivers++;
                    }
                });
            }
        });

        if (data.crewMovements) {
            data.crewMovements.forEach(cl => {
                if (crewLeadersFilter.some(name => name === cl.crewLeader)) {
                    filteredData.crewMovements.push(cl);
                    filteredData.totalSlowMoves = filteredData.totalSlowMoves + cl.slowMoves;
                    filteredData.totalLagMoves = filteredData.totalLagMoves + cl.lagMoves;
                    filteredData.bulkLostTime = filteredData.bulkLostTime + cl.bulkLostTime;
                    filteredData.bulkLostLagTime = filteredData.bulkLostLagTime + cl.bulkLostLagTime;
                    if (cl.activeLeader.active === true) {
                        filteredData.activeCrews++;
                    }
                }
            });
        }
        crewLeadersFilter.forEach(crewLeader => {
            // @ts-ignore
            if (data.crewLeaderCrewMoves && data.crewLeaderCrewMoves[crewLeader]) {
                // @ts-ignore
                data.crewLeaderCrewMoves[crewLeader].forEach(driver =>
                    // @ts-ignoree
                    driver.assignedTimesArray.forEach(function(element) {
                        if (element.status !== 'IN CAR') {
                            filteredData.totalBulkMoves++;
                        }
                    }),
                );
            }
        });
        if (filteredData.totalBulkMoves > 0) {
            filteredData.lagPercentage = Math.round((filteredData.totalLagMoves / filteredData.totalBulkMoves) * 100);
            filteredData.slowMovePercentage = Math.round(
                (filteredData.totalSlowMoves / filteredData.totalBulkMoves) * 100,
            );
        }
        if (data.lotResultsSummary) {
            data.lotResultsSummary.forEach(lot => {
                let crewLeaderArray = [];
                let activeCrews = 0;

                // @ts-ignore
                for (let n = 0; n < lot.crewleaders.length; n++) {
                    if (
                        crewLeadersFilter.some(
                            filteredCrewLeader =>
                                // @ts-ignore
                                filteredCrewLeader === lot.crewleaders[n].crewleader,
                        )
                    ) {
                        let exists = false;
                        let index = 0;
                        // @ts-ignore
                        for (let i = 0; i < filteredData.lotResultsSummary.length; i++) {
                            // @ts-ignore
                            if (filteredData.lotResultsSummary[i].lotOrigin === lot.lotid) {
                                exists = true;
                                index = i;
                                break;
                            }
                        }
                        // @ts-ignore
                        crewLeaderArray.push(lot.crewleaders[n].crewleader);

                        if (!exists) {
                            // @ts-ignore
                            filteredData.lotResultsSummary.push({
                                // @ts-ignore
                                lotOrigin: lot.lotid,
                                // @ts-ignore
                                totalLostTime: lot.crewleaders[n].losttime + lot.crewleaders[n].lagtime,
                                // @ts-ignore
                                bulkLostTime: lot.crewleaders[n].losttime,
                                // @ts-ignore
                                bulkLostLagTime: lot.crewleaders[n].lagtime,
                                // @ts-ignore
                                totalMDPs: lot.crewleaders[n].mdp,
                                // @ts-ignore
                                totalLTLs: lot.crewleaders[n].ltl,
                                // @ts-ignore
                                lessThanTwoMinuteMoves: lot.crewleaders[n].lt2mins,
                                // @ts-ignore
                                activeCrews: 1,
                                // @ts-ignore
                                activeCrewData: { activeCrews: 1, crewLeaders: crewLeaderArray },
                            });
                        } else {
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].totalLostTime =
                                // @ts-ignore
                                filteredData.lotResultsSummary[index].totalLostTime +
                                // @ts-ignore
                                lot.crewleaders[n].losttime +
                                // @ts-ignore
                                lot.crewleaders[n].lagtime;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].bulkLostTime =
                                // @ts-ignore
                                filteredData.lotResultsSummary[index].bulkLostTime + lot.crewleaders[n].losttime;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].bulkLostLagTime =
                                // @ts-ignore
                                filteredData.lotResultsSummary[index].bulkLostLagTime + lot.crewleaders[n].lagtime;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].totalMDPs =
                                // @ts-ignore
                                filteredData.lotResultsSummary[index].totalMDPs + lot.crewleaders[n].mdp;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].totalLTLs =
                                // @ts-ignore
                                filteredData.lotResultsSummary[index].totalLTLs + lot.crewleaders[n].ltl;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].lessThanTwoMinuteMoves =
                                // @ts-ignore
                                filteredData.lotResultsSummary[index].lessThanTwoMinuteMoves +
                                // @ts-ignore
                                lot.crewleaders[n].lt2mins;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].activeCrews++;
                            // @ts-ignore
                            activeCrews = filteredData.lotResultsSummary[index].activeCrews;
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].activeCrewData = {
                                activeCrews: activeCrews,
                                crewLeaders: crewLeaderArray,
                            };
                        }
                    }
                }
            });
        }
    } else if (filter !== '') {
        filteredData = {
            activeCrews: data.activeCrews,
            activeDrivers: data.activeDrivers,
            totalSlowMoves: data.totalSlowMoves,
            totalLagMoves: data.totalLagMoves,
            totalBulkMoves: data.totalBulkMoves,
            lagPercentage: data.lagPercentage,
            slowMovePercentage: data.slowMovePercentage,
            bulkLostTime: data.bulkLostTime,
            bulkLostLagTime: data.bulkLostLagTime,
            driverMetrics: data.driverMetrics,
            crewMovements: data.crewMovements,
            crewLeaderCrewMoves: data.crewLeaderCrewMoves,
            crewLeadersFilterArr: data.crewLeadersFilterArr,
            lotResultsSummary: [],
            saleLanes: data.saleLanes,
            totalDrivers: data.totalDrivers,
        };
        if (data.lotResultsSummary) {
            data.lotResultsSummary.forEach(lot => {
                let crewLeaderArray = [];
                let activeCrews = 0;
                // @ts-ignore
                for (let n = 0; n < lot.crewleaders.length; n++) {
                    let exists = false;
                    let index = 0;
                    // @ts-ignore
                    for (let i = 0; i < filteredData.lotResultsSummary.length; i++) {
                        // @ts-ignore
                        if (filteredData.lotResultsSummary[i].lotOrigin === lot.lotid) {
                            exists = true;
                            index = i;
                            break;
                        }
                    }
                    // @ts-ignore
                    crewLeaderArray.push(lot.crewleaders[n].crewleader);
                    if (!exists) {
                        // @ts-ignore
                        filteredData.lotResultsSummary.push({
                            // @ts-ignore
                            lotOrigin: lot.lotid,
                            // @ts-ignore
                            totalLostTime: lot.crewleaders[n].losttime + lot.crewleaders[n].lagtime,
                            // @ts-ignore
                            bulkLostTime: lot.crewleaders[n].losttime,
                            // @ts-ignore
                            bulkLostLagTime: lot.crewleaders[n].lagtime,
                            // @ts-ignore
                            totalMDPs: lot.crewleaders[n].mdp,
                            // @ts-ignore
                            totalLTLs: lot.crewleaders[n].ltl,
                            // @ts-ignore
                            lessThanTwoMinuteMoves: lot.crewleaders[n].lt2mins,
                            // @ts-ignore
                            activeCrews: 1,
                            // @ts-ignore
                            activeCrewData: { activeCrews: 1, crewLeaders: crewLeaderArray },
                        });
                    } else {
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].totalLostTime =
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].totalLostTime +
                            // @ts-ignore
                            lot.crewleaders[n].losttime +
                            // @ts-ignore
                            lot.crewleaders[n].lagtime;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].bulkLostTime =
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].bulkLostTime + lot.crewleaders[n].losttime;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].bulkLostLagTime =
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].bulkLostLagTime + lot.crewleaders[n].lagtime;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].totalMDPs =
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].totalMDPs + lot.crewleaders[n].mdp;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].totalLTLs =
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].totalLTLs + lot.crewleaders[n].ltl;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].lessThanTwoMinuteMoves =
                            // @ts-ignore
                            filteredData.lotResultsSummary[index].lessThanTwoMinuteMoves + lot.crewleaders[n].lt2mins;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].activeCrews++;
                        // @ts-ignore
                        activeCrews = filteredData.lotResultsSummary[index].activeCrews;
                        // @ts-ignore
                        filteredData.lotResultsSummary[index].activeCrewData = {
                            activeCrews: activeCrews,
                            crewLeaders: crewLeaderArray,
                        };
                    }
                }
            });
        }
    }
    filteredData.lotResultsSummary.forEach(lot => {
        // @ts-ignore
        lot.totalLostTime = Math.round(lot.totalLostTime);
        // @ts-ignore
        lot.bulkLostTime = Math.round(lot.bulkLostTime);
        // @ts-ignore
        lot.bulkLostLagTime = Math.round(lot.bulkLostLagTime);
    });
    return filteredData;
};

const socketMiddleware: Middleware = store => next => action => {
    switch (action.type) {
        case 'WS_JOIN_ROOM': {
            store.dispatch(setLoading(true));
            const {
                auctionCode,
                filter,
                mode,
            }: { auctionCode: string; filter?: string; mode: string } = action.socketEvent;
            const event = generateEventString(auctionCode, mode);
            socketConnection.emit('joinRoom', { room: auctionCode, mode: mode });
            socketConnection.addEventListener(event, (data: DomainState) => {
                // @ts-ignore
                let filteredData = processSocketData(filter, data);
                store.dispatch(updateDomain(filteredData));
            });
            socketConnection.on('reconnect', () => {
                console.log('=================================');
                console.log('Reconnecting to room/auction ', auctionCode);
                console.log('=================================');
                socketConnection.emit('joinRoom', {
                    room: auctionCode,
                    mode: mode,
                });
            });
            next(action);
            break;
        }
        case 'WS_LEAVE_ROOM': {
            store.dispatch(setLoading(true));
            const { auctionCode, mode } = action.socketEvent;
            socketConnection.emit('leaveRoom', { room: auctionCode, mode: mode });
            socketConnection.removeAllListeners();
            next(action);
            break;
        }
        default:
            next(action);
    }
};
export default socketMiddleware;
