import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import { AuctionDetails, UserProfile, SocketEvent } from '../../global';
import { updateSelectedAuction, updateBulkFilterStr } from '../../redux/user/action';
import { wsJoinRoom, wsLeaveRoom } from '../../redux/socket/action';
import { Dispatch } from 'redux';
import { setDraftFilters, setFinalFilters } from '../../redux/activeCrews/actions';
import styles from './AuctionDropdown.module.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

interface OuterProps {
    userProfile: UserProfile;
    auctionList: AuctionDetails[];
    crewLeadersFilterArr: string[];
    draftFilters: string[];
    updateSelectedAuction: (auction: string) => Dispatch<any>;
    updateBulkFilterStrFn: (bulkFilterStr: string) => Dispatch<any>;
    setDraftFiltersFn: (filters: string[]) => void;
    setFinalFiltersFn: (filters: string[]) => void;
    wsJoinRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    wsLeaveRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    selectedAuction: string;
    selectedDashboard: string;
    bulkFilterStr: string;
}

const theme = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                fontSize: 'large',
            },
            selectMenu: {
                fontSize: 'large',
            },
            icon: {
                color: '#005ba8',
                fontSize: 'x-large',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: 'large',
            },
        },
    },
});

class AuctionDropdown extends React.Component<OuterProps> {
    private readonly auctionDropdownRef: any;

    public constructor(props: any) {
        super(props);
        this.auctionDropdownRef = React.createRef();
    }

    public render() {
        const { auctionList, selectedAuction } = this.props;

        return (
            <div className={styles.dropdownDiv} id="select-auction-dropdown">
                <div className={styles.dropdownLabel} id="auction-dropdown-label">
                    Auction
                </div>
                <FormControl variant="outlined" className={styles.dropdown}>
                    <ThemeProvider theme={theme}>
                        <Select
                            value={selectedAuction}
                            onChange={this.handleSwitchAuction}
                            input={<OutlinedInput labelWidth={0} name="auction" />}
                        >
                            {auctionList.map(auction => (
                                <MenuItem value={auction.AuctionCode} key={auction.AuctionCode}>
                                    {auction.AuctionName}
                                </MenuItem>
                            ))}
                        </Select>
                    </ThemeProvider>
                </FormControl>
            </div>
        );
    }
    /**
     *This function is triggered when a user selects the auction dropdown and changes their auction.
     *It drops all listeners on the socket connection and joins a new room with the new auction code.
     *
     * @private
     * @memberof AuctionDropdown
     */
    private handleSwitchAuction = (event: React.ChangeEvent<{}>, child: ReactNode): void => {
        try {
            // @ts-ignore
            const selectedAuctionData = child.props;
            const {
                userProfile,
                updateSelectedAuction,
                wsLeaveRoomFn,
                wsJoinRoomFn,
                selectedAuction,
                selectedDashboard,
                setFinalFiltersFn,
                setDraftFiltersFn,
                bulkFilterStr,
                updateBulkFilterStrFn,
            } = this.props;

            wsLeaveRoomFn({
                auctionCode: selectedAuction,
                user: userProfile.UserName,
                filter: selectedDashboard === 'bulk' ? bulkFilterStr : '',
                mode: selectedDashboard,
            });

            updateSelectedAuction(selectedAuctionData.value);
            wsJoinRoomFn({
                auctionCode: selectedAuctionData.value,
                user: userProfile.UserName,
                filter: '*ALL*',
                mode: selectedDashboard,
            });
            setFinalFiltersFn([]);
            setDraftFiltersFn([]);
            updateBulkFilterStrFn('');
        } catch (e) {
            console.error('ERROR switching auction locations. ', e);
        }
    };
}

export const mapDispatchToProps = (dispatch: any) => ({
    updateSelectedAuction: (auction: string) => dispatch(updateSelectedAuction(auction)),
    updateBulkFilterStrFn: (bulkFilterStr: string) => dispatch(updateBulkFilterStr(bulkFilterStr)),
    setDraftFiltersFn: (filters: string[]) => dispatch(setDraftFilters(filters)),
    setFinalFiltersFn: (filters: string[]) => dispatch(setFinalFilters(filters)),
    wsJoinRoomFn: (socketEvent: SocketEvent) => dispatch(wsJoinRoom(socketEvent)),
    wsLeaveRoomFn: (socketEvent: SocketEvent) => dispatch(wsLeaveRoom(socketEvent)),
});

const mapStateToProps = (state: any) => ({
    userProfile: state.user.profile,
    auctionList: state.user.auctionList,
    selectedAuction: state.user.selectedAuction,
    bulkFilterStr: state.user.bulkFilterStr,
    selectedDashboard: state.user.selectedDashboard,
    crewLeadersFilterArr: state.domain.crewLeadersFilterArr,
    draftFilters: state.activeCrews.draftFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDropdown);
