import {
    generateGenericCellData,
    generateTimeCellData,
    generateActiveCrewDataCell,
} from '../../../reusableComponents/CellTransformers/cellTransformers';
import { CommonTableHeaderData } from '../../../global';

export const lotResultsSummaryHeaders: CommonTableHeaderData[] = [
    {
        id: 'lot-origin-header',
        key: 'lotOrigin',
        displayName: 'Lot Origin',
        transform: generateGenericCellData,
        isSortable: false,
    },
    {
        id: 'total-lost-time-header',
        key: 'totalLostTime',
        displayName: 'Total Lost Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'bulk-lost-time-header',
        key: 'bulkLostTime',
        displayName: 'Bulk Lost Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'bulk-lost-lag-time-header',
        key: 'bulkLostLagTime',
        displayName: 'Bulk Lost Lag Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'total-mdps-header',
        key: 'totalMDPs',
        displayName: 'Total # of MDPs',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'total-ltls-header',
        key: 'totalLTLs',
        displayName: 'Total # of LTLs',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'less-than-two-minute-moves-header',
        key: 'lessThanTwoMinuteMoves',
        displayName: '< 2 Minute Moves',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'active-crews-header',
        key: 'activeCrewData',
        displayName: 'Active Crews',
        transform: generateActiveCrewDataCell,
        isSortable: true,
    },
];
