import { SocketEvent } from '../../global';
export const WS_JOIN_ROOM = 'WS_JOIN_ROOM';
export const WS_LEAVE_ROOM = 'WS_LEAVE_ROOM';

interface WsJoinRoomAction {
    type: typeof WS_JOIN_ROOM;
    socketEvent: SocketEvent;
}

interface WsLeaveRoomAction {
    type: typeof WS_LEAVE_ROOM;
    socketEvent: SocketEvent;
}

export type WsActionTypes = WsJoinRoomAction | WsLeaveRoomAction;
