import React from 'react';
import DriverMetricsTable from '../../../reusableComponents/DataTables/DriverMetricsTable/DriverMetricsTable';
import DriverMovementTable from '../../../reusableComponents/DataTables/DriverMovementTable/DriverMovementTable';

import { connect } from 'react-redux';
import { clearSelectedCrewLeader } from '../../../redux/activeCrews/actions';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import styles from './DriverMetricsView.module.css';
import AlertBar from '../../../reusableComponents/AlertBar/AlertBar';
import { SocketEvent } from '../../../global';
import { wsJoinRoom, wsLeaveRoom } from '../../../redux/socket/action';

interface OuterProps {
    selectedCrewLeader: string;
    returnToCrewLeadersView: () => void;
    crewLeadersFilterArr: string[];
    finalFilters: string[];
}
class DriverMetricsView extends React.Component<OuterProps> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public render() {
        const { selectedCrewLeader } = this.props;

        return (
            <div>
                <div className={styles.crewMovementsContainer}>
                    <AlertBar />
                    <div className={styles.titleContainer}>
                        <div className={styles.tableTitle}>
                            <span id="driver-metrics-table-title">{`${selectedCrewLeader}'s Crew`}</span>
                            <div
                                id={'driver-metrics-view-back-container'}
                                className={styles.backButtonContainer}
                                onClick={this.handleBackToCrewsClick}
                            >
                                <ChevronLeft style={{ color: '#005ba8', fontSize: '3rem' }} />
                                {'Back to Crews'}
                            </div>
                        </div>
                    </div>
                    <DriverMetricsTable />
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <div id="driver-movements-table-title" className={styles.tableTitle}>
                            {`${selectedCrewLeader}'s Crew Moves`}
                        </div>
                    </div>
                    <DriverMovementTable tableId="driver-movements" />
                </div>
            </div>
        );
    }

    private generateCrewLeaderStr = (filters: string[], crewLeadersFilterArr: string[]) => {
        const updatedArr = crewLeadersFilterArr.filter(crewLeader => !filters.includes(crewLeader));
        return updatedArr.toString();
    };

    private handleBackToCrewsClick = () => {
        const { returnToCrewLeadersView, finalFilters, crewLeadersFilterArr } = this.props;

        returnToCrewLeadersView();

        const generatedCrewLeadersArr = this.generateCrewLeaderStr(finalFilters, crewLeadersFilterArr);
        const crewLeadersFilterStr = finalFilters.length > 0 ? generatedCrewLeadersArr.toString() : '*ALL*';

        try {
            // @ts-ignore
            const { userProfile, selectedAuction, selectedCrewLeader, wsJoinRoomFn, wsLeaveRoomFn } = this.props;
            wsLeaveRoomFn({
                auctionCode: selectedAuction,
                user: userProfile.UserName,
                filter: selectedCrewLeader,
                mode: 'bulk',
            });
            wsJoinRoomFn({
                auctionCode: selectedAuction,
                filter: crewLeadersFilterStr,
                user: userProfile.UserName,
                mode: 'bulk',
            });
        } catch (e) {
            console.error('ERROR switching auction locations. ', e);
        }
    };
}

export const mapStateToProps = (state: any) => ({
    selectedCrewLeader: state.activeCrews.selectedCrewLeader,
    userProfile: state.user.profile,
    selectedAuction: state.user.selectedAuction,
    crewLeadersFilterArr: state.domain.crewLeadersFilterArr,
    finalFilters: state.activeCrews.finalFilters,
});

export const mapDispatchToProps = (dispatch: any) => ({
    returnToCrewLeadersView: () => dispatch(clearSelectedCrewLeader()),
    wsLeaveRoomFn: (socketEvent: SocketEvent) => dispatch(wsLeaveRoom(socketEvent)),
    wsJoinRoomFn: (socketEvent: SocketEvent) => dispatch(wsJoinRoom(socketEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverMetricsView);
