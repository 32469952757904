import React from 'react';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import multipleDropoffIcon from '../../assets/MultipleDropoff.svg';
import lessThanFullLoads from '../../assets/LessThanFullLoads.svg';

interface OuterProps {
    toolTipTitle: string;
    issueIcon: string;
}

const StyledToolTip = withStyles({
    tooltip: {
        fontSize: '1.4rem',
    },
})(Tooltip);

const IssueFlag: React.FC<OuterProps> = props => {
    const { toolTipTitle, issueIcon } = props;
    return (
        <StyledToolTip title={toolTipTitle} placement="bottom">
            <img
                alt=""
                src={issueIcon === 'MULTIPLEDROPOFF' ? multipleDropoffIcon : lessThanFullLoads}
                style={{ marginRight: '1rem' }}
            />
        </StyledToolTip>
    );
};

export default IssueFlag;
