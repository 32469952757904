/**
 *This utility is used for our sorting functionality to compare two strings
 *
 * @param {(number | string)} firstElement
 * @param {(number | string)} secondElement
 * @returns 1 if the first element is greater and -1 if it's less than
 */
function generateComparison(firstElement: number | string, secondElement: number | string) {
    let comparison = 0;
    if (firstElement > secondElement) {
        comparison = 1;
    } else if (firstElement < secondElement) {
        comparison = -1;
    }
    return comparison;
}

/**
 *This utility is used for our sorting functionality to compare two arrays
 *
 * @param {string} key
 * @param {string} order
 * @returns 1 if the first element is greater and -1 if it's less than
 */
export const compareArrayValues = (key: string, order: string) => {
    return function(firstValue: any, secondValue: any) {
        if (!firstValue.hasOwnProperty(key) || !secondValue.hasOwnProperty(key)) {
            return 0;
        }

        const firstValueArraySize = firstValue[key].length;
        const secondValueArraySize = secondValue[key].length;
        const comparison = generateComparison(firstValueArraySize, secondValueArraySize);

        return order === 'desc' ? comparison * -1 : comparison;
    };
};

/**
 *This utility is used for our sorting functionality to compare two numbers
 *
 * @param {string} key
 * @param {string} order
 * @returns 1 if the first element is greater and -1 if it's less than
 */
export const compareNumberValues = (key: string, order: string) => {
    return function(firstValue: any, secondValue: any) {
        if (!firstValue.hasOwnProperty(key) || !secondValue.hasOwnProperty(key)) {
            return 0;
        }

        const firstNumber = firstValue[key] === null ? -1 : firstValue[key];
        const secondNumber = secondValue[key] === null ? -1 : secondValue[key];
        const comparison = generateComparison(firstNumber, secondNumber);

        return order === 'desc' ? comparison * -1 : comparison;
    };
};

/**
 *This utility is used for our sorting functionality to compare two movements
 *
 * @param {string} key
 * @param {string} order
 * @returns 1 if the first element is greater and -1 if it's less than
 */
export const compareMovementValues = (key: string, order: string) => {
    return function(firstValue: any, secondValue: any) {
        if (!firstValue.hasOwnProperty(key) || !secondValue.hasOwnProperty(key)) {
            return 0;
        }

        const firstNumber = firstValue[key].time === null ? -1 : firstValue[key].time;
        const secondNumber = secondValue[key].time === null ? -1 : secondValue[key].time;
        const comparison = generateComparison(firstNumber, secondNumber);

        return order === 'desc' ? comparison * -1 : comparison;
    };
};

/**
 *This utility is used for our sorting functionality to compare two strings after getting
 *the strings out of containing objects
 *
 * @param {(number | string)} firstElement
 * @param {(number | string)} secondElement
 * @returns 1 if the first element is greater and -1 if it's less than
 */
export const compareStringValues = (key: string, order: string) => {
    return function(firstValue: any, secondValue: any) {
        const firstString: string = firstValue[key].toLowerCase();
        const secondString: string = secondValue[key].toLowerCase();
        const comparison = generateComparison(firstString, secondString);

        return order === 'desc' ? comparison * -1 : comparison;
    };
};

/**
 *This utility is used for our sorting functionality to compare two active values after getting
 *the strings out of containing objects
 *
 * @param {(number | string)} firstElement
 * @param {(number | string)} secondElement
 * @returns 1 if the first element is greater and -1 if it's less than
 */
export const compareActiveValues = (key: string, order: string) => {
    return function(firstValue: any, secondValue: any) {
        const firstString: string = firstValue[key].active;
        const secondString: string = secondValue[key].active;
        const comparison = generateComparison(firstString, secondString);

        return order === 'desc' ? comparison * -1 : comparison;
    };
};
