import React from 'react';
import { CommonTableHeaderData } from '../../global';
import Table from '@material-ui/core/Table';
import CommonTableHeader from './CommonTableHeader';
import CommonTableBody from './CommonTableBody';
import tableStyles from './CommonTable.module.css';

interface OuterProps {
    id: string;
    rows: any[];
    headers: CommonTableHeaderData[];
    sortFunction: any;
    columnToSort: string;
    sortDirection: string;
    filterFunction?: () => any;
    styles?: any;
    innerRef?: any;
    isScrollBarHidden?: boolean;
    scrollFn?: () => void;
}

const CommonTable: React.FC<OuterProps> = props => {
    const {
        headers,
        id,
        rows,
        sortFunction,
        sortDirection,
        columnToSort,
        filterFunction,
        styles,
        innerRef,
        isScrollBarHidden,
        scrollFn,
    } = props;

    return (
        <div id={id + '-table'} style={styles && styles}>
            <Table>
                <CommonTableHeader
                    headers={headers}
                    sortFunction={sortFunction}
                    columnToSort={columnToSort}
                    sortDirection={sortDirection}
                    filterFunction={filterFunction}
                    tableId={id}
                />
                <CommonTableBody
                    rows={rows}
                    headers={headers}
                    ref={innerRef}
                    isScrollBarHidden={isScrollBarHidden}
                    scrollFn={scrollFn && scrollFn}
                />
            </Table>
            {rows.length <= 0 && (
                <div>
                    <h2 className={tableStyles.noDataHeader}>No Data Available</h2>
                </div>
            )}
        </div>
    );
};

export default CommonTable;
