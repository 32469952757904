import React from 'react';
import {
    IssuesFlaggedData,
    BenchmarkedTime,
    ActiveLeaderDriver,
    ActiveCrewData,
    ActiveDriver,
    LotInfo,
} from '../../global';
import IssueFlag from '../IssueFlag/IssueFlag';
import CrewLeaderTableCell from '../../reusableComponents/DataTables/CrewMovementTable/CrewLeaderTableCell';
import DriverTableCell from '../../reusableComponents/DriverTableCell/DriverTableCell';
import LotOriginCell from '../../reusableComponents/DriverTableCell/LotOriginCell';
import styles from '../../reusableComponents/DataTables/DriverMovementTable/DriverMovementTable.module.css';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { isNil } from 'lodash';

const StyledToolTip = withStyles({
    tooltip: {
        fontSize: '1.4rem',
    },
})(Tooltip);

export const formatTime = (value: number) => {
    const calculatedHours = Math.floor(value / 60);
    const calculatedMinutes = value % 60;
    return value > 59 ? `${calculatedHours}h ${calculatedMinutes}m` : `${calculatedMinutes}m`;
};

/**
 *This function generates a display time for cells showing time
 *
 * @param {string} value
 * @returns Display time
 */
export const generateDateCellData = (value: string) => {
    if (Boolean(value)) {
        let time = moment(moment.utc(value))
            .local()
            .format('h:mm:ss A');
        return <span>{time}</span>;
    }
    return '--';
};

/**
 *This function generates a display path (ex. 301 -> 160) for cells showing move path
 *
 * @param {string} value
 * @returns Move path
 */
export const generatePathCellData = (value: string) => {
    if (Boolean(value)) {
        let pathArray: string[] = value.split('-');
        if (pathArray.length > 1) {
            let movepath = null;
            for (let i = 0; i < pathArray.length - 1; i++) {
                if (movepath) {
                    movepath = movepath + pathArray[i] + ' \u2192 ';
                } else {
                    movepath = pathArray[i] + ' \u2192 ';
                }
            }
            movepath = movepath + pathArray[pathArray.length - 1];
            return <span>{movepath}</span>;
        }
        return '--';
    }
    return '--';
};

/**
 *This function generates the issues flagged icons along with their tooltips for cells showing issues flagged
 *
 * @param {string} value
 * @returns Issues flagged icons with tooltips
 */
export const generateIssueFlags = (issues: IssuesFlaggedData[]) => {
    const flags = [];
    for (let i = 0; i < issues.length; i += 1) {
        flags.push(<IssueFlag key={i} toolTipTitle={issues[i].displayName} issueIcon={issues[i].value} />);
    }

    return flags.length === 0 ? '--' : flags;
};

/**
 *This function generates generic cell data showing '--' for null values
 *
 * @param {string} value
 * @returns Cell data or '--'
 */
export const generateGenericCellData = (value: string | number | null) => {
    return Boolean(value) ? value : '--';
};

/**
 *
 * @param lotInfo
 * return cell data for lot Origin
 */
export const generateLotOriginData = (lotInfo: LotInfo) => {
    let lot = !isNil(lotInfo.lotOrigin) ? lotInfo.lotOrigin.toString() : '--';
    return <LotOriginCell lotOrigin={lot} mdpLot={lotInfo.mdpLot} />;
};

/**
 *This function generates a display time for cells showing time
 *
 * @param {string} value
 * @returns Display time
 */
export const generateTimeCellData = (value: number | null) => {
    return value !== null ? formatTime(value) : '--';
};

/**
 *This function generates a crew leader name that is clickable with styling for cells showing crew leader
 *
 * @param {string} value
 * @returns Clickable crew leader names
 */
export const generateCrewLeaderTableCell = (activeLeader: ActiveLeaderDriver) => {
    return <CrewLeaderTableCell name={activeLeader.name} active={activeLeader.active} />;
};

export const generateDriverTableCell = (activeDriver: ActiveDriver) => {
    return (
        <DriverTableCell name={activeDriver.name} active={activeDriver.active} unassigned={activeDriver.unassigned} />
    );
};

export const visualizer = true;

/**
 *This function generates the progress bar with styling and the move time for cells showing
 * data related to move time
 *
 * @param {string} value
 * @returns Move time with styled progress bar
 */
export const generateBenchmarkedTime = (benchmarkedTime: BenchmarkedTime) => {
    let time = benchmarkedTime && benchmarkedTime.time ? benchmarkedTime.time : 0;
    let benchmark = benchmarkedTime && benchmarkedTime.benchmark ? benchmarkedTime.benchmark / 60.0 : 0;
    let timeString = formatTime(time);

    let fullWidth = time > 30 ? 30 : time > (100 * benchmark) / 80 ? time : (100 * benchmark) / 80;
    let width = (100 * time) / fullWidth;
    let benchPercentage = (100 * benchmark) / fullWidth;
    let timePercentage = (100 * time) / 30;

    return Boolean(benchmark) ? (
        <div>
            <div className={styles.movementStatusContainer}>
                <div
                    className={styles.movementStatus}
                    style={{
                        width: width + '%',
                        backgroundColor: time >= benchmark && time > 0 ? '#c33a00' : '#0d8340',
                    }}
                ></div>
                <div className={styles.movementStatusIndicator} style={{ left: benchPercentage + '%' }}></div>
            </div>
            <div id="driver-movements-time">{timeString}</div>
        </div>
    ) : (
        <div>
            <div className={styles.movementStatusContainer}>
                <div
                    className={styles.movementStatus}
                    style={{
                        width: timePercentage + '%',
                        backgroundColor:
                            benchmarkedTime && benchmarkedTime.status && benchmarkedTime.status === 'IN VAN'
                                ? '#3d98ff'
                                : '#c33a00',
                    }}
                ></div>
            </div>
            <div id="driver-movements-time">{timeString}</div>
        </div>
    );
};

export const generateActiveCrewDataCell = (activeCrewData: ActiveCrewData) => {
    return (
        <StyledToolTip title={activeCrewData.crewLeaders.join(', ')} placement="left">
            <span>{activeCrewData.activeCrews}</span>
        </StyledToolTip>
    );
};
