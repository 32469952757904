import React from 'react';
import descendingSortImg from '../../assets/sort-descending.svg';
import ascendingSortImg from '../../assets/sort-ascending.svg';
import defaultSortImg from '../../assets/sort-default.svg';

const generateArrowWithDirection = (direction: string) => {
    return direction === 'desc' ? (
        <img alt="" src={descendingSortImg} className="descending-sort-arrow" />
    ) : (
        <img alt="" src={ascendingSortImg} className="ascending-sort-arrow" />
    );
};

const generateSortArrow = (currentlySorting: boolean, direction: string) => {
    return currentlySorting ? (
        generateArrowWithDirection(direction)
    ) : (
        <img alt="" src={defaultSortImg} className="default-sort-arrow" />
    );
};

interface OuterProps {
    currentlySorting: boolean;
    direction: string;
}

const TableSortArrow: React.FC<OuterProps> = props => {
    const { currentlySorting, direction } = props;
    return generateSortArrow(currentlySorting, direction);
};

export default TableSortArrow;
