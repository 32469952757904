import { DomainState, UpdateDomainActionType, SetLoadingActionType } from '../../global';
import { UPDATE_DOMAIN, SET_LOADING } from './constants';

export const updateDomain = (data: DomainState): UpdateDomainActionType => {
    return {
        type: UPDATE_DOMAIN,
        data,
    };
};
export const setLoading = (loading: boolean): SetLoadingActionType => {
    return {
        type: SET_LOADING,
        loading,
    };
};
