import React from 'react';
import { UserProfile } from '../../global';
import { connect } from 'react-redux';
import BulkLostLagTimeView from './BulkLostLagTimeView/BulkLostLagTimeView';
import styles from './BulkLostLagTime.module.css';

interface OuterProps {
    selectedCrewLeader: string;
    selectedAuction: string;
    userProfile: UserProfile;
}

class BulkLostLagTime extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public componentDidMount(): void {}

    public componentWillUnmount(): void {}

    public render() {
        return (
            <div id="bulk-lost-lag-time-container" className={styles.container}>
                {<BulkLostLagTimeView />}
            </div>
        );
    }
}

export const mapDispatchToProps = (dispatch: any) => ({});

export const mapStateToProps = (state: any) => ({
    selectedCrewLeader: state.activeCrews.selectedCrewLeader,
    selectedAuction: state.user.selectedAuction,
    userProfile: state.user.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkLostLagTime);
