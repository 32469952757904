import React from 'react';
import { connect } from 'react-redux';
import IssueFlag from '../IssueFlag/IssueFlag';

interface OuterProps {
    lotOrigin: string;
    mdpLot: boolean;
}

class LotOriginCell extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public render() {
        const { lotOrigin, mdpLot } = this.props;

        return (
            <div>
                {lotOrigin}

                <span>
                    {mdpLot && (
                        <span style={{ paddingLeft: '1rem' }}>
                            <IssueFlag key="0" toolTipTitle="Multi Drop Off Lot" issueIcon="MULTIPLEDROPOFF" />
                        </span>
                    )}
                    {!mdpLot && <span style={{ marginRight: '4rem' }}></span>}
                </span>
            </div>
        );
    }
}

export default connect(null)(LotOriginCell);
