import React from 'react';
import { connect } from 'react-redux';
import styles from './SaleMovesTable.module.css';
import smStyles from './SaleMovesProductivity.module.css';
import { /*Warning,*/ HelpOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DriversDetails, SaleLanes } from '../../global';

interface AppState {
    allFilterChecked: boolean;
    dialogOpen: boolean;
    filterOpen: boolean;
    filteredLanes: number[];
    searchedDriver: string;
    searchAll: boolean;
    searchLane: number;
    searchValue: string;
    searchType: string;
    reverseSort: boolean;
}

interface OuterProps {
    saleLanes: SaleLanes[];
}

const StyledToolTip = withStyles({
    tooltip: {
        fontSize: '1.4rem',
    },
})(Tooltip);

const ColorButton = withStyles(theme => ({
    root: {
        color: '#000',
        backgroundColor: '#ffc20e',
        '&:hover': {
            backgroundColor: '#e3b327',
        },
    },
    label: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
    },
}))(Button);

export class SaleMovesTable extends React.Component<OuterProps, AppState> {
    private tableHeaderRef: any;
    private tableDataRef: any;

    public constructor(props: any) {
        super(props);
        this.state = {
            allFilterChecked: true,
            filteredLanes: [],
            dialogOpen: false,
            filterOpen: false,
            searchedDriver: '',
            searchAll: false,
            searchLane: -1,
            searchValue: '',
            searchType: '',
            reverseSort: false,
        };

        this.tableHeaderRef = React.createRef();
        this.tableDataRef = React.createRef();
    }

    private handleClick = (laneId: string, searchValue: string, searchType: string, e: any) => {
        e.preventDefault();
        this.setState({
            dialogOpen: true,
            searchAll: false,
            searchLane: parseInt(laneId),
            searchValue: searchValue,
            searchType:
                searchType === 'assigned'
                    ? 'Assigned Drivers in Lane'
                    : searchType === 'highDeviation'
                    ? 'Drivers with High Cycle Time Deviation in Lane'
                    : 'Drivers Away from Sale in Lane',
        });
        return false;
    };

    private handleClose = (e: any) => {
        e.preventDefault();
        this.setState({
            dialogOpen: false,
        });
        return false;
    };

    private handleSetFilter = () => {
        this.setState({
            filterOpen: true,
        });
    };

    private handleClearFilter = () => {
        this.setState({
            allFilterChecked: true,
            filteredLanes: [],
        });
    };

    private handleFilterClose = () => {
        this.setState({
            filterOpen: false,
        });
    };

    private handleFilterAllChange = (e: any) => {
        this.setState({
            filteredLanes: [],
            allFilterChecked: !this.state.allFilterChecked,
        });
    };

    private handleFilterChange = (lane: string, e: any) => {
        let filteredLanes = this.state.filteredLanes;
        let laneId = parseInt(lane);

        if (
            this.props.saleLanes.length !== 0 &&
            !this.state.allFilterChecked &&
            this.state.filteredLanes.length === 0
        ) {
            this.props.saleLanes.forEach(lane => {
                filteredLanes.push(parseInt(lane.id));
            });
        }

        if (filteredLanes.indexOf(laneId) >= 0) {
            filteredLanes.splice(filteredLanes.indexOf(laneId), 1);
        } else {
            filteredLanes.push(laneId);
        }

        this.setState({
            filteredLanes: filteredLanes,
            allFilterChecked: filteredLanes.length === 0,
        });
    };

    private handleDriverSearch = () => {
        this.setState({
            dialogOpen: true,
            searchAll: true,
            searchLane: -1,
            searchedDriver: '',
        });
    };

    private checkFilters = (laneId: string) => {
        return (
            this.state.allFilterChecked ||
            (this.state.filteredLanes.indexOf(parseInt(laneId)) === -1 && this.state.filteredLanes.length > 0)
        );
    };

    private filterDrivers = (event: any) => {
        this.setState({
            searchedDriver: event.target.value.toLowerCase(),
        });
    };

    private handleSelectChange = (event: any) => {
        this.setState({
            searchLane: parseInt(event.target.value),
        });
    };

    private toggleSort = () => {
        this.setState({
            reverseSort: !this.state.reverseSort,
        });
    };

    private combineAllDriversDetails = (lanes: SaleLanes[]) => {
        let allDrivers: DriversDetails[] = [];

        lanes.forEach(lane => {
            lane.driverEfficiency.drivers.forEach(obj => {
                allDrivers.push(obj);
            });
        });

        return allDrivers;
    };

    private handleHeaderScroll = () => {
        this.tableDataRef.current.scrollLeft = this.tableHeaderRef.current.scrollLeft;
    };

    private handleDataScroll = () => {
        this.tableHeaderRef.current.scrollLeft = this.tableDataRef.current.scrollLeft;
    };

    private convertDecMinsToMinSec = (value: string) => {
        let timeValue = parseFloat(value);
        let min = timeValue >= 1 ? Math.trunc(timeValue) + 'm ' : '';
        let secValue = Math.round((((timeValue % 1) * 60) / 100) * 100);
        let sec = secValue > 0 ? secValue + 's' : '';
        return min + sec ? min + sec : '--';
    };

    private convertSecsToMinSec = (value: string) => {
        let timeValue = parseFloat(value);
        let minValue = Math.floor(timeValue / 60);
        let secValue = timeValue - minValue * 60;
        let min = minValue >= 1 ? minValue + 'm ' : '';
        let sec = secValue > 0 ? secValue + 's' : '';
        return min + sec ? min + sec : '--';
    };

    public render() {
        const { saleLanes } = this.props;

        const driversDetails = this.combineAllDriversDetails(saleLanes);

        return (
            <div>
                <div className={smStyles.innerFixed}>
                    <div className={styles.saleMovesContainer} id="sale-moves-productivity-table">
                        <div>
                            <table className={styles.saleMovesTable + ' ' + styles.rowHeaders}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableTitle}>
                                            <div className={styles.filterContainer}>
                                                Lane
                                                <div>
                                                    {this.state.allFilterChecked &&
                                                        this.state.filteredLanes.length === 0 && (
                                                            <StyledToolTip title={'Filter Lanes'} placement="top">
                                                                <span
                                                                    id="all-lanes-filter"
                                                                    className={styles.filterInactive}
                                                                    onClick={this.handleSetFilter}
                                                                ></span>
                                                            </StyledToolTip>
                                                        )}
                                                    {(!this.state.allFilterChecked ||
                                                        this.state.filteredLanes.length > 0) && (
                                                        <StyledToolTip title={'Filter Lanes'} placement="top">
                                                            <span
                                                                id="all-lanes-filter"
                                                                className={styles.filterActive}
                                                                onClick={this.handleSetFilter}
                                                            ></span>
                                                        </StyledToolTip>
                                                    )}
                                                    {(!this.state.allFilterChecked ||
                                                        this.state.filteredLanes.length > 0) && (
                                                        <StyledToolTip title={'Clear Lane Filters'} placement="top">
                                                            <span
                                                                id="filter-lane-clear-button"
                                                                className={styles.filterClear}
                                                                onClick={this.handleClearFilter}
                                                            ></span>
                                                        </StyledToolTip>
                                                    )}
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div
                            className={styles.dataTable}
                            hidden={
                                this.state.filteredLanes.length === saleLanes.length ||
                                (!this.state.allFilterChecked && this.state.filteredLanes.length === 0)
                            }
                            id="sales-lane-table"
                            ref={this.tableHeaderRef}
                            onScroll={this.handleHeaderScroll}
                        >
                            <table className={styles.saleMovesTable}>
                                <thead>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <th key={key} className={styles.tableTitle}>
                                                        <span className="lanes">{lane.id}</span>{' '}
                                                        {/*saleAlerts.some(
                                                            saleAlert => saleAlert.laneNumber === Number(lane.id),
                                                        ) && <Warning fontSize="inherit" />*/}
                                                    </th>
                                                );
                                            })}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>

                <div className={smStyles.innerRemaining}>
                    <div className={styles.saleMovesContainer} id="sale-moves-productivity-table">
                        <div>
                            <table className={styles.saleMovesTable + ' ' + styles.rowHeaders}>
                                <tbody>
                                    <tr>
                                        <td className={styles.tableHeader} id="sale-moves-table-header-lane-efficiency">
                                            Lane Efficiency
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-pace">Pace</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-block-time">Block Time</span>{' '}
                                            <StyledToolTip
                                                id="tooltip-block-time"
                                                title={
                                                    'The average of the time the last three vehicles spent on the block.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={styles.saleMovesTable + ' ' + styles.rowHeaders}>
                                <tbody>
                                    <tr>
                                        <td
                                            className={styles.tableHeader}
                                            id="sale-moves-table-header-queue-efficiency"
                                        >
                                            Queue Efficiency
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-behind-block">
                                                Est. # of Vehicles Behind Block
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-in-queue">
                                                Optimal # of Vehicles in Queue
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-cars-on-way">
                                                Total Scanned Cars on Way
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={styles.saleMovesTable + ' ' + styles.rowHeaders}>
                                <tbody>
                                    <tr>
                                        <td className={styles.tableHeader} id="sale-moves-table-header-cycle-times">
                                            Cycle Times
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-avg-pre-block-time">
                                                Avg. Pre-Block Time
                                            </span>{' '}
                                            <StyledToolTip
                                                title={
                                                    'The average time taken from driver assignment to auction block during the past 10 minutes.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-avg-block-time">Avg. Block Time</span>{' '}
                                            <StyledToolTip
                                                id="tooltip-avg-block-time"
                                                title={
                                                    'The average time each vehicle spent on block during the past 10 minutes.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-avg-post-block-time">
                                                Avg. Post-Block Time
                                            </span>{' '}
                                            <StyledToolTip
                                                id="tooltip-avg-post-block-time"
                                                title={
                                                    'The average time a driver took from exiting the block to their next vehicle assignment during the past 10 minutes.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-block-total">Total</span>{' '}
                                            <StyledToolTip
                                                id="tooltip-total-block-time"
                                                title={
                                                    'The average of total cycle-time from the start of one vehicle assignment to the start of the next vehicle assignment during the past 10 minutes.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={styles.saleMovesTable + ' ' + styles.rowHeaders}>
                                <tbody>
                                    <tr>
                                        <td
                                            className={styles.tableHeader}
                                            id="sale-moves-table-header-driver-efficiency"
                                        >
                                            <div className={styles.driverSearchContainer}>
                                                Driver Efficiency
                                                <div>
                                                    <StyledToolTip title={'Search All Drivers'} placement="top">
                                                        <span
                                                            id="driver-search-button"
                                                            className={styles.searchButton}
                                                            onClick={this.handleDriverSearch}
                                                        ></span>
                                                    </StyledToolTip>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-in-lane"># of Assigned Drivers</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-optimal-drivers">Optimal # of Drivers</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-available-drivers">
                                                # of Available Drivers
                                            </span>{' '}
                                            <StyledToolTip
                                                id="tooltip-available-drivers"
                                                title={'Number of drivers assigned to lane who are present.'}
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-high-ct-deviation">
                                                Drivers w/ High C.T. Deviation
                                            </span>{' '}
                                            <StyledToolTip
                                                id="tooltip-high-deviation"
                                                title={
                                                    'The number of drivers in the lane that have a cycle-time significantly above average.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span id="sale-moves-row-header-away-from-sale">
                                                # of Drivers Away from Sale
                                            </span>{' '}
                                            <StyledToolTip
                                                id="tooltip-away-from-sale"
                                                title={
                                                    'Drivers who were assigned to the lane but are not available to move vehicles.'
                                                }
                                                placement="right"
                                            >
                                                <HelpOutline fontSize="inherit" />
                                            </StyledToolTip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {(this.state.filteredLanes.length === saleLanes.length ||
                            (!this.state.allFilterChecked && this.state.filteredLanes.length === 0)) && (
                            <div className={styles.noDataTitle}>
                                <h2>No Data Available</h2>
                            </div>
                        )}

                        <div
                            className={styles.dataTable}
                            hidden={
                                this.state.filteredLanes.length === saleLanes.length ||
                                (!this.state.allFilterChecked && this.state.filteredLanes.length === 0)
                            }
                            id="sales-lane-table"
                            ref={this.tableDataRef}
                            onScroll={this.handleDataScroll}
                        >
                            <table className={styles.saleMovesTable}>
                                {/* Lane Efficiency */}
                                <tbody>
                                    <tr>
                                        <td colSpan={saleLanes.length} className={styles.tableHeader}>
                                            <span className={styles.hidden}>@</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        key={key}
                                                        className={
                                                            lane.laneEfficiency.pace.value === 'Fast'
                                                                ? styles.fast
                                                                : lane.laneEfficiency.pace.value === 'Good'
                                                                ? styles.good
                                                                : lane.laneEfficiency.pace.value === 'Slow'
                                                                ? styles.slow
                                                                : styles.down
                                                        }
                                                    >
                                                        <span className={styles.paceValue}>
                                                            {lane.laneEfficiency.pace.value}
                                                        </span>
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.laneEfficiency.blockTime.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {this.convertSecsToMinSec(lane.laneEfficiency.blockTime.value)}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                </tbody>
                            </table>
                            <table className={styles.saleMovesTable}>
                                {/* Queue Efficiency */}
                                <tbody>
                                    <tr>
                                        <td colSpan={saleLanes.length} className={styles.tableHeader}>
                                            <span className={styles.hidden}>@</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.queueEfficiency.behindBlock.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {lane.queueEfficiency.behindBlock.value}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.queueEfficiency.inQueue.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {lane.queueEfficiency.inQueue.value}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={lane.queueEfficiency.onWay.alert ? styles.alert : ''}
                                                        key={key}
                                                    >
                                                        {lane.queueEfficiency.onWay.value}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                </tbody>
                            </table>
                            <table className={styles.saleMovesTable}>
                                {/* Cycle Times */}
                                <tbody>
                                    <tr>
                                        <td colSpan={saleLanes.length} className={styles.tableHeader}>
                                            <span className={styles.hidden}>@</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={lane.cycleTimes.preBlock.alert ? styles.alert : ''}
                                                        key={key}
                                                    >
                                                        {this.convertDecMinsToMinSec(lane.cycleTimes.preBlock.value)}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={lane.cycleTimes.block.alert ? styles.alert : ''}
                                                        key={key}
                                                    >
                                                        {this.convertDecMinsToMinSec(lane.cycleTimes.block.value)}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={lane.cycleTimes.postBlock.alert ? styles.alert : ''}
                                                        key={key}
                                                    >
                                                        {this.convertDecMinsToMinSec(lane.cycleTimes.postBlock.value)}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={lane.cycleTimes.total.alert ? styles.alert : ''}
                                                        key={key}
                                                    >
                                                        {this.convertDecMinsToMinSec(lane.cycleTimes.total.value)}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                </tbody>
                            </table>
                            <table className={styles.saleMovesTable}>
                                {/* Driver Efficiency */}
                                <tbody>
                                    <tr>
                                        <td colSpan={saleLanes.length} className={styles.tableHeader}>
                                            <span className={styles.hidden}>@</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.driverEfficiency.assigned.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        <a
                                                            href="/"
                                                            onClick={this.handleClick.bind(
                                                                this,
                                                                lane.id,
                                                                lane.driverEfficiency.assigned.value,
                                                                'assigned',
                                                            )}
                                                            id={'sale-moves-driver-efficiency-' + lane.id}
                                                        >
                                                            {lane.driverEfficiency.assigned.value}
                                                        </a>
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.driverEfficiency.optimal.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {lane.driverEfficiency.optimal.value}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.driverEfficiency.available.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {lane.driverEfficiency.available.value}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.driverEfficiency.highDeviation.alert
                                                                ? styles.alert
                                                                : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {lane.driverEfficiency.highDeviation.alert ? (
                                                            <a
                                                                href="/"
                                                                onClick={this.handleClick.bind(
                                                                    this,
                                                                    lane.id,
                                                                    lane.driverEfficiency.highDeviation.value,
                                                                    'highDeviation',
                                                                )}
                                                            >
                                                                {lane.driverEfficiency.highDeviation.value}
                                                            </a>
                                                        ) : (
                                                            lane.driverEfficiency.highDeviation.value
                                                        )}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                    <tr>
                                        {saleLanes
                                            .filter((lane, _) => this.checkFilters(lane.id))
                                            .sort((a, b) => {
                                                return a.id - b.id;
                                            })
                                            .map((lane: any, key: any) => {
                                                return (
                                                    <td
                                                        className={
                                                            lane.driverEfficiency.awayFromSale.alert ? styles.alert : ''
                                                        }
                                                        key={key}
                                                    >
                                                        {lane.driverEfficiency.awayFromSale.alert ? (
                                                            <a
                                                                href="/"
                                                                onClick={this.handleClick.bind(
                                                                    this,
                                                                    lane.id,
                                                                    lane.driverEfficiency.awayFromSale.value,
                                                                    'awayFromSale',
                                                                )}
                                                            >
                                                                {lane.driverEfficiency.awayFromSale.value}
                                                            </a>
                                                        ) : (
                                                            lane.driverEfficiency.awayFromSale.value
                                                        )}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.dialogOpen}
                    fullWidth={true}
                    maxWidth={'sm'}
                    id="driver-search-modal"
                >
                    <DialogTitle>
                        <div className={styles.titleContainer}>
                            <h1 className={styles.dialogTitle}>
                                {this.state.searchAll
                                    ? 'Search All Drivers'
                                    : this.state.searchValue +
                                      ' ' +
                                      this.state.searchType +
                                      ' ' +
                                      this.state.searchLane}
                            </h1>
                            <span className={styles.dialogClose} onClick={this.handleClose}></span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.dialogSearch}>
                            <div className={styles.driverSearchContainer}>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={this.filterDrivers}
                                    name="sale-moves-driver-efficiency-search"
                                    id="sale-moves-driver-efficiency-search"
                                    value={this.state.searchedDriver}
                                />
                                {this.state.searchAll && (
                                    <select
                                        className={styles.mySelect}
                                        onChange={this.handleSelectChange}
                                        id="driver-search-lane-select"
                                        name="driver-search-lane-select"
                                    >
                                        <option value={-1}>All Lanes</option>
                                        {saleLanes.map((lane: any, key: number) => {
                                            return (
                                                <option key={lane.id} value={lane.id}>
                                                    Lane {lane.id}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            </div>
                        </div>
                        <div>
                            <table className={styles.dialogTable}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeader + ' table-header'}>
                                            Driver{' '}
                                            <span
                                                className={this.state.reverseSort ? styles.sortUp : styles.sortDown}
                                                onClick={this.toggleSort}
                                                id="driver-search-toggle-sort"
                                            ></span>
                                        </th>
                                        {this.state.searchAll && (
                                            <th className={styles.tableHeader + ' table-header'}>Lane</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {driversDetails
                                        .filter(driver => {
                                            return (
                                                (driver.firstName.toLowerCase().search(this.state.searchedDriver) !==
                                                    -1 ||
                                                    driver.lastName.toLowerCase().search(this.state.searchedDriver) !==
                                                        -1) /*||
                                                    driver.company.toLowerCase().search(this.state.searchedDriver) !==
                                                        -1*/ &&
                                                (driver.lane === this.state.searchLane || this.state.searchLane === -1)
                                            );
                                        })
                                        .sort((a, b) =>
                                            this.state.reverseSort
                                                ? b.lastName.localeCompare(a.lastName)
                                                : a.lastName.localeCompare(b.lastName),
                                        )
                                        .map((driver: any, key: any, arr: any) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <span
                                                            className={
                                                                this.state.searchAll ? 'driver-filter-driver-name' : ''
                                                            }
                                                        >
                                                            {driver.lastName}, {driver.firstName}
                                                        </span>
                                                    </td>
                                                    {this.state.searchAll && <td>{driver.lane}</td>}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <ColorButton
                            onClick={this.handleClose}
                            color="primary"
                            size="large"
                            id="driver-search-ok-button"
                        >
                            OK
                        </ColorButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    id="lane-filter-modal"
                    onClose={this.handleFilterClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.filterOpen}
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle>
                        <div className={styles.titleContainer}>
                            <h1 className={styles.dialogTitle}>Filter Lanes</h1>
                            <span className={styles.dialogClose} onClick={this.handleFilterClose}></span>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <ul className={styles.filterList}>
                                <li>
                                    <input
                                        name="sale-moves-lane-filter-all"
                                        id="sale-moves-lane-filter-all"
                                        type="checkbox"
                                        checked={this.state.allFilterChecked}
                                        onChange={this.handleFilterAllChange}
                                    />
                                    All
                                </li>
                                {saleLanes.map((lane: any, key: number) => {
                                    return (
                                        <li key={lane.id}>
                                            <input
                                                name={'sale-moves-lane-filter-option-' + lane.id}
                                                id={'sale-moves-lane-filter-option-' + lane.id}
                                                type="checkbox"
                                                checked={
                                                    this.state.allFilterChecked ||
                                                    (this.state.filteredLanes.indexOf(parseInt(lane.id)) === -1 &&
                                                        this.state.filteredLanes.length > 0)
                                                }
                                                onChange={this.handleFilterChange.bind(this, lane.id)}
                                            />
                                            <span id={'sale-moves-land-filter-title-' + lane.id}>Lane {lane.id}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <ColorButton
                            onClick={this.handleFilterClose}
                            color="primary"
                            size="large"
                            id="lane-filter-ok-button"
                        >
                            OK
                        </ColorButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = (state: any) => ({
    saleLanes: state.domain.saleLanes,
});

export default connect(mapStateToProps)(SaleMovesTable);
/*
    const saleAlerts: any[] = [
    // uncomment sale alerts above
    {
        laneNumber: 1,
        messages: [
            { message: 'test-message', run: '45' },
            { message: 'test-message1', run: '99' },
        ],
    },
    {
        laneNumber: 4,
        messages: [
            { message: 'test-message', run: '10' },
            { message: 'test-message1', run: '20' },
        ],
    },
];
*/
