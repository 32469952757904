import React from 'react';
import { SaleAlert } from '../../global';
import { connect } from 'react-redux';
import styles from '../SaleAlertBar/SaleAlertBar.module.css';

interface OuterProps {
    saleAlerts: SaleAlert[];
}
let saleAlerts: SaleAlert[] = [
    {
        laneNumber: 3,
        messages: [
            { message: 'Vehicle scanned out of order and on the way to the block (1YVHP84DX55M13025)', run: '8' },
        ],
    },
    {
        laneNumber: 6,
        messages: [
            { message: 'Vehicle not crossing the block (JH4KA7650RC007283)', run: '4' },
            { message: 'Vehicle needs sticker (1B7HF13Y2WJ198530)', run: '5' },
        ],
    },
];

class SaleAlertBar extends React.Component<OuterProps, {}> {
    public constructor(props: any) {
        super(props);
    }
    private alertList = (data: SaleAlert[]) => {
        const listItems = data.map(alert => {
            return alert.messages.map((message, index) => (
                <li key={index} className={styles.warningHeader + ' sales-alert-bar'}>
                    <strong>
                        <span id={'sale-moves-alert-' + alert.laneNumber}>
                            Lane {alert.laneNumber}, Run {message.run}
                        </span>
                    </strong>{' '}
                    - {message.message}.
                </li>
            ));
        });

        return listItems;
    };

    /**
     *This function displays the alerts for sale day reports
     *at the top of the sale mode reporting
     *
     * @memberof SaleAlertBar
     */

    public render() {
        const { saleAlerts } = this.props;
        let alertlist = this.alertList(saleAlerts);
        return (
            saleAlerts.length > 0 && (
                <div className={styles.saleAlertBar}>
                    <ul>{alertlist}</ul>
                </div>
            )
        );
    }
}

export const mapStateToProps = (state: any) => ({
    saleAlerts: saleAlerts,
});

export default connect(mapStateToProps)(SaleAlertBar);
