import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CrewMovementData, UserProfile, SocketEvent } from '../../global';
import DialogCheckbox from './DialogCheckbox';
import { addDraftFilter, removeDraftFilter, setDraftFilters, setFinalFilters } from '../../redux/activeCrews/actions';
import { wsJoinRoom, wsLeaveRoom } from '../../redux/socket/action';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { updateBulkFilterStr } from '../../redux/user/action';
import styled from '@material-ui/core/styles/styled';
import isEqual from 'lodash.isequal';

interface OuterProps {
    isOpen: boolean;
    closeFilterFunction: () => any;
    crewMovements: CrewMovementData[];
    crewLeadersFilterArr: string[];
    draftFilters: string[];
    finalFilters: string[];
    userProfile: UserProfile;
    selectedAuction: string;
    wsJoinRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    wsLeaveRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    removeCrewLeaderFilterFn: (crewLeader: string) => void;
    addCrewLeaderFilterFn: (crewLeader: string) => void;
    setDraftFiltersFn: (filters: string[]) => void;
    setFinalFiltersFn: (filters: string[]) => void;
    updateBulkFilterStrFn: (bulkFilterStr: string) => Dispatch<any>;
}

const StyledDialogContent = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '45rem',
    width: '30rem',
    padding: '0',
});

const StyledButton = styled(Button)({
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#003468',
});

class CrewMovementFilterDialog extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public render() {
        const { isOpen, draftFilters, finalFilters, crewLeadersFilterArr } = this.props;

        return (
            <Dialog id="crew-leader-modal" open={isOpen} onClose={this.handleCancelOnClick}>
                <DialogTitle id="crew-movements-filter-title">
                    <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>Crew Leader</div>
                </DialogTitle>
                <StyledDialogContent>
                    <DialogCheckbox
                        id={'crew-movements-filter-dialog-all-checkbox'}
                        label={'All'}
                        onCheck={this.handleAllCrewLeaderCheckboxCheck}
                        onUnCheck={this.handleAllCrewLeaderCheckboxUnCheck}
                        isChecked={draftFilters.length === 0}
                    />
                    {crewLeadersFilterArr.map((crewMovement, key) => {
                        return (
                            <DialogCheckbox
                                key={key}
                                isChecked={!draftFilters.includes(crewMovement)}
                                label={crewMovement}
                                onCheck={this.handleCrewLeaderCheckboxCheck(crewMovement)}
                                onUnCheck={this.handleCrewLeaderCheckboxUnCheck(crewMovement)}
                            />
                        );
                    })}
                </StyledDialogContent>
                <DialogActions>
                    <StyledButton
                        id="crew-movements-filter-cancel-button"
                        color="primary"
                        onClick={this.handleCancelOnClick}
                        disabled={isEqual(draftFilters, finalFilters)}
                    >
                        Cancel
                    </StyledButton>
                    <StyledButton id="crew-movements-filter-ok-button" color="primary" onClick={this.handleOkOnClick}>
                        OK
                    </StyledButton>
                </DialogActions>
            </Dialog>
        );
    }

    private generateCrewLeaderStr = (draftFilters: string[], crewLeadersFilterArr: string[]) => {
        const updatedArr = crewLeadersFilterArr.filter(crewLeader => !draftFilters.includes(crewLeader));
        return updatedArr.toString();
    };

    private handleCancelOnClick = () => {
        const { closeFilterFunction, setDraftFiltersFn, finalFilters } = this.props;

        setDraftFiltersFn(finalFilters);
        closeFilterFunction();
    };

    private handleOkOnClick = () => {
        const { closeFilterFunction, setFinalFiltersFn, draftFilters, crewLeadersFilterArr, finalFilters } = this.props;

        const prevGeneratedCrewLeadersArr =
            finalFilters.length > 0 ? this.generateCrewLeaderStr(finalFilters, crewLeadersFilterArr) : '';
        const prevCrewLeadersFilterStr = prevGeneratedCrewLeadersArr.toString();
        const generatedCrewLeadersArr = this.generateCrewLeaderStr(draftFilters, crewLeadersFilterArr);
        const crewLeadersFilterStr = draftFilters.length > 0 ? generatedCrewLeadersArr.toString() : '*ALL*';
        setFinalFiltersFn(draftFilters);
        closeFilterFunction();
        try {
            // @ts-ignore
            const { userProfile, selectedAuction, wsJoinRoomFn, wsLeaveRoomFn, updateBulkFilterStrFn } = this.props;
            updateBulkFilterStrFn(crewLeadersFilterStr);
            wsLeaveRoomFn({
                auctionCode: selectedAuction,
                user: userProfile.UserName,
                filter: prevCrewLeadersFilterStr,
                mode: 'bulk',
            });
            wsJoinRoomFn({
                auctionCode: selectedAuction,
                user: userProfile.UserName,
                filter: crewLeadersFilterStr,
                mode: 'bulk',
            });
        } catch (e) {
            console.error('ERROR switching auction locations. ', e);
        }
    };

    private handleCrewLeaderCheckboxCheck = (crewLeader: string) => () => {
        this.props.addCrewLeaderFilterFn(crewLeader);
    };

    private handleCrewLeaderCheckboxUnCheck = (crewLeader: string) => () => {
        this.props.removeCrewLeaderFilterFn(crewLeader);
    };

    private handleAllCrewLeaderCheckboxCheck = () => {
        const crewLeaders = this.props.crewLeadersFilterArr.map(crewLeader => {
            return crewLeader;
        });
        this.props.setDraftFiltersFn(crewLeaders);
    };

    private handleAllCrewLeaderCheckboxUnCheck = () => {
        this.props.setDraftFiltersFn([]);
    };
}

export const mapDispatchToProps = (dispatch: any) => ({
    addCrewLeaderFilterFn: (crewLeader: string) => dispatch(addDraftFilter(crewLeader)),
    removeCrewLeaderFilterFn: (crewLeader: string) => dispatch(removeDraftFilter(crewLeader)),
    setDraftFiltersFn: (filters: string[]) => dispatch(setDraftFilters(filters)),
    setFinalFiltersFn: (filters: string[]) => dispatch(setFinalFilters(filters)),
    updateBulkFilterStrFn: (bulkFilterStr: string) => dispatch(updateBulkFilterStr(bulkFilterStr)),
    wsJoinRoomFn: (socketEvent: SocketEvent) => dispatch(wsJoinRoom(socketEvent)),
    wsLeaveRoomFn: (socketEvent: SocketEvent) => dispatch(wsLeaveRoom(socketEvent)),
});

export const mapStateToProps = (state: any) => ({
    userProfile: state.user.profile,
    selectedAuction: state.user.selectedAuction,
    crewMovements: state.domain.crewMovements,
    crewLeadersFilterArr: state.domain.crewLeadersFilterArr,
    draftFilters: state.activeCrews.draftFilters,
    finalFilters: state.activeCrews.finalFilters,
});

export default connect(mapStateToProps, mapDispatchToProps)(CrewMovementFilterDialog);
