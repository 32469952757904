import React from 'react';
import styles from '../../../reusableComponents/DataTables/DataTables.module.css';
import DriverMovementTable from '../../../reusableComponents/DataTables/DriverMovementTable/DriverMovementTable';

interface OuterProps {}

const TotalBulkMovesView: React.FC<OuterProps> = (props: OuterProps) => {
    return (
        <div>
            <div className={styles.crewMovementsContainer}>
                <div className={styles.titleContainer}>
                    <div id="ongoing-moves-table-title" className={styles.tableTitle}>
                        Ongoing Moves (3.6 Moves Per Hour)
                    </div>
                </div>
                <DriverMovementTable tableId="ongoing-moves" />
            </div>
            <div>
                <div className={styles.titleContainer}>
                    <div id="completed-moves-table-title" className={styles.tableTitle}>
                        Completed Moves
                    </div>
                </div>
                <DriverMovementTable tableId="completed-moves" />
            </div>
        </div>
    );
};

export default TotalBulkMovesView;
