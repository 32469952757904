import React from 'react';
import { connect } from 'react-redux';
import styles from './DriverTableCell.module.css';
import IssueFlag from '../IssueFlag/IssueFlag';

interface OuterProps {
    name: string;
    active: boolean;
    unassigned: boolean;
}

class DriverTableCell extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public render() {
        const { name, active, unassigned } = this.props;

        return (
            <div
                className={
                    active
                        ? 'driverTableCellDivActive ' +
                          styles.driverTableCellDiv +
                          ' ' +
                          styles.driverTableCellDivActive
                        : 'driverTableCellDiv ' + styles.driverTableCellDiv
                }
            >
                {name}
                {unassigned && (
                    <span style={{ paddingLeft: '1rem' }}>
                        <IssueFlag key="0" toolTipTitle="Unassigned Drivers" issueIcon="UNASSIGNEDDRIVER" />
                    </span>
                )}
            </div>
        );
    }
}

export default connect(null)(DriverTableCell);
