import axios from 'axios';
import { rtrBackendUrl } from '../config/config';

declare global {
    interface Window {
        EmployeeWorkbench: any;
    }
}

class UserService {
    /**
     *This function gets a user profile from the backend using the authorization token in local storage
     *The authorization token is a jwt representing the user profile generated by the backend.
     *
     * @static
     * @memberof UserService
     */
    public static getProfile = async (token: string) => {
        return await axios
            .get(rtrBackendUrl + '/sso/user', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(function(response) {
                window.localStorage.setItem('auth_token', token);
                //delete the authorization token from the url
                window.history.pushState(null, '', window.location.href.split('?')[0]);
                return Promise.resolve(response.data.profile);
            })
            .catch(function(error) {
                window.localStorage.removeItem('auth_token');
                console.error('Fetch user data failed', error);
                return Promise.reject(error);
            });
    };

    /**
     *This function redirects the browser to the OKTA user login page
     *
     * @static
     * @memberof UserService
     */
    public static login = async () => {
        window.location.href = rtrBackendUrl + '/sso/redirect';
    };

    /**
     *This function creates a service initiated logout request with the RTR backend to OAM
     *using the authorization token in local storage
     *
     * @static
     * @memberof UserService
     */
    public static logout = async () => {
        window.location.href = rtrBackendUrl + '/sso/logout';
        /*await axios
            .get(rtrBackendUrl + '/sso/logout', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(function(response) {
                //window.location.assign(response.data.frontendRootURL);
                return Promise.resolve();
            })
            .catch(function(error) {
                //console.error('Logout failed', error);
                return Promise.reject();
            });*/
    };
}

export default UserService;
