import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import App from './views/App';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './redux/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import socketMiddleware from './middleware/socketMiddleware';
import UserService from './services/UserService';
import { rtrBackendUrl } from './config/config';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, socketMiddleware)));

/**
 *Load the application if the window request unless the path contains the logout params
 *Then remove the auth token and redirect to workbench logout
 */
ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Route
                path="/sso/logout"
                render={() => {
                    const token = String(window.localStorage.getItem('auth_token'));
                    window.localStorage.removeItem(token);
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    UserService.logout();
                    return <div />;
                }}
            />
            <Route
                path="/saml/logout"
                render={() => {
                    const token = String(window.localStorage.getItem('auth_token'));
                    window.localStorage.removeItem(token);
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.location.href = rtrBackendUrl + '/saml/logout';
                    return <div />;
                }}
            />
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
