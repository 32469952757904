import React from 'react';
import AppTab from './AppTab';
import Tabs from '@material-ui/core/Tabs';
import { BulkMoveTabLabelData } from '../global';
import withStyles from '@material-ui/core/styles/withStyles';

interface AppTabContainerProps {
    tabs: BulkMoveTabLabelData[];
    selectedTabIndex: number;
    onTabChange: (event: React.ChangeEvent<{}>, value: number) => void;
}

const StyledTabContainer = withStyles({
    root: {
        background: '#555555',
        padding: '0 0.5rem 1rem 0.5rem',
        marginTop: '-16px',
    },
    indicator: {
        height: '2px',
        backgroundColor: '#ffffff',
    },
})(Tabs);

const AppTabContainer: React.FC<AppTabContainerProps> = props => {
    const { tabs, onTabChange, selectedTabIndex } = props;
    return (
        <StyledTabContainer value={selectedTabIndex} id="sub-nav-menu">
            {tabs.map((tab, index) => {
                return <AppTab onTabChange={onTabChange} key={index} data={tab} />;
            })}
        </StyledTabContainer>
    );
};

export default AppTabContainer;
