import {
    ActionType,
    SetFiltersActionType,
    SetSelectedCrewLeaderActionType,
    UpdateDraftFilterActionType,
    UpdateTableSortActionType,
} from '../../global';
import {
    ADD_DRAFT_FILTER,
    REMOVE_DRAFT_FILTER,
    SET_FINAL_FILTERS,
    SET_ACTIVE_CREWS_CREW_MOVEMENTS_DEFAULT_SORT,
    SET_DRIVER_MOVEMENTS_DEFAULT_SORT,
    SET_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_DEFAULT_SORT,
    SET_DRAFT_FILTERS,
    UPDATE_ACTIVE_CREWS_CREW_MOVEMENTS_TABLE_SORT,
    UPDATE_DRIVER_MOVEMENTS_TABLE_SORT,
    UPDATE_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_TABLE_SORT,
    SET_SELECTED_CREW_LEADER,
    CLEAR_SELECTED_CREW_LEADER,
    UPDATE_ACTIVE_CREWS_DRIVER_METRICS_TABLE_SORT,
    SET_ACTIVE_CREWS_DRIVER_METRICS_DEFAULT_SORT,
} from './constants';

export const updateCrewMovementTableSort = (tableHeader: string): UpdateTableSortActionType => {
    return {
        type: UPDATE_ACTIVE_CREWS_CREW_MOVEMENTS_TABLE_SORT,
        tableHeader,
    };
};

export const updateDriverMovementTableSort = (tableHeader: string): UpdateTableSortActionType => {
    return {
        type: UPDATE_DRIVER_MOVEMENTS_TABLE_SORT,
        tableHeader,
    };
};

export const updateLotResultsSummaryTableSort = (tableHeader: string): UpdateTableSortActionType => {
    return {
        type: UPDATE_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_TABLE_SORT,
        tableHeader,
    };
};

export const updateDriverMetricsTableSort = (tableHeader: string): UpdateTableSortActionType => {
    return {
        type: UPDATE_ACTIVE_CREWS_DRIVER_METRICS_TABLE_SORT,
        tableHeader,
    };
};

export const setCrewMovementsDefaultSort = (): ActionType => {
    return {
        type: SET_ACTIVE_CREWS_CREW_MOVEMENTS_DEFAULT_SORT,
    };
};

export const setDriverMovementsDefaultSort = (): ActionType => {
    return {
        type: SET_DRIVER_MOVEMENTS_DEFAULT_SORT,
    };
};

export const setLotResultsSummaryDefaultSort = (): ActionType => {
    return {
        type: SET_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_DEFAULT_SORT,
    };
};

export const setDriverMetricsDefaultSort = (): ActionType => {
    return {
        type: SET_ACTIVE_CREWS_DRIVER_METRICS_DEFAULT_SORT,
    };
};

export const addDraftFilter = (filter: string): UpdateDraftFilterActionType => {
    return {
        type: ADD_DRAFT_FILTER,
        filter,
    };
};

export const removeDraftFilter = (filter: string): UpdateDraftFilterActionType => {
    return {
        type: REMOVE_DRAFT_FILTER,
        filter,
    };
};

export const setFinalFilters = (filters: string[]): SetFiltersActionType => {
    return {
        type: SET_FINAL_FILTERS,
        filters,
    };
};

export const setDraftFilters = (filters: string[]): SetFiltersActionType => {
    return {
        type: SET_DRAFT_FILTERS,
        filters,
    };
};

export const setSelectedCrewLeader = (crewLeader: string): SetSelectedCrewLeaderActionType => {
    return {
        type: SET_SELECTED_CREW_LEADER,
        crewLeader,
    };
};

export const clearSelectedCrewLeader = (): ActionType => {
    return {
        type: CLEAR_SELECTED_CREW_LEADER,
    };
};
