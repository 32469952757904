import React from 'react';
import styles from './BulkMovesProductivity.module.css';
import BulkMovesTabContainer from './BulkMovesTabContainer';
import { bulkMovesTabHeaders, bulkMovesTabHeadersDrivers } from '../../utils/headers';
import { connect } from 'react-redux';
import ActiveCrews from '.././activeCrews/ActiveCrews';
import TotalBulkMoves from '.././totalBulkMoves/TotalBulkMoves';
import LagPercentage from '.././lagPercentage/LagPercentage';
import SlowMovePercentage from './../slowMovePercentage/SlowMovePercentage';
import BulkLostTime from './../bulkLostTime/BulkLostTime';
import BulkLostLagTime from './../bulkLostLagTime/BulkLostLagTime';
import { Dispatch } from 'redux';
import { AuctionDetails, UserProfile, CrewMovementData, SocketEvent } from '../../global';
import { setSelectedCrewLeader, setDraftFilters, setFinalFilters } from '../../redux/activeCrews/actions';
import { updateBulkFilterStr } from '../../redux/user/action';
import { wsJoinRoom, wsLeaveRoom } from '../../redux/socket/action';
import AuctionDropdown from '../../reusableComponents/AuctionDropdown/AuctionDropdown';
import CrewMovementFilterDialog from '../../reusableComponents/CrewMovementFilterDialog/CrewMovementFilterDialog';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FilterList, HighlightOff } from '@material-ui/icons';
import { formatTime } from '../../reusableComponents/CellTransformers/cellTransformers';

interface BulkMovesState {
    selectedTabIndex: number;
    openCrewMovementsFilter: boolean;
}

interface OuterProps {
    wsJoinRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    wsLeaveRoomFn: (socketEvent: SocketEvent) => Dispatch<any>;
    selectedCrewLeader: string;
    activeCrewCount: number;
    activeDriverCount: number;
    totalBulkMoves: number;
    lagPercentage: number;
    slowMovePercentage: number;
    bulkLostTime: number;
    bulkLostLagTime: number;
    userProfile: UserProfile;
    auctionList: AuctionDetails[];
    selectedAuction: string;
    bulkFilterStr: string;
    setSelectedCrewLeaderFn: (crewLeader: string) => void;
    crewMovements: CrewMovementData[];
    crewLeadersFilterArr: string[];
    draftFilters: string[];
    finalFilters: string[];
    setDraftFiltersFn: (filters: string[]) => void;
    setFinalFiltersFn: (filters: string[]) => void;
    updateBulkFilterStrFn: (bulkFilterStr: string) => Dispatch<any>;
    loading: boolean;
}

const activeCountIndex = 0;
const totalBulkMovesIndex = 1;
const lagPercentageIndex = 2;
const slowMovePercentageIndex = 3;
const bulkLostTimeIndex = 4;
const bulkLostLagTimeIndex = 5;

class BulkMovesProductivity extends React.Component<OuterProps, BulkMovesState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
            openCrewMovementsFilter: false,
        };
    }

    /**
     *In this lifecycle function we check for an auth token in local storage and if we have one we
     *verify the token. If not we initiate OAM login. Then we setup the socket connection for the
     *current auction.
     *
     * @returns {Promise<void>}
     * @memberof App
     */
    public async componentDidMount(): Promise<void> {
        const { userProfile, selectedAuction, finalFilters, wsJoinRoomFn, bulkFilterStr } = this.props;

        if (userProfile && selectedAuction) {
            finalFilters.length > 0
                ? wsJoinRoomFn({
                      auctionCode: selectedAuction,
                      filter: bulkFilterStr,
                      user: userProfile.UserName,
                      mode: 'bulk',
                  })
                : wsJoinRoomFn({
                      auctionCode: selectedAuction,
                      filter: '*ALL*',
                      user: userProfile.UserName,
                      mode: 'bulk',
                  });
        }
    }

    /**
     *In this lifecycle function we leave the current room on the socket connection
     *if the user is logged inf
     *
     * @memberof App
     */
    public componentWillUnmount(): void {
        const { userProfile } = this.props;
        if (userProfile) {
            this.handleLeaveRoom();
        }
    }

    public render() {
        return (
            <div className={styles.appContainer}>
                {this.props.userProfile && this.props.auctionList && this.props.selectedAuction && this.content()}
            </div>
        );
    }

    private content() {
        const { selectedTabIndex, openCrewMovementsFilter } = this.state;
        const {
            selectedCrewLeader,
            finalFilters,
            activeCrewCount,
            activeDriverCount,
            totalBulkMoves,
            lagPercentage,
            slowMovePercentage,
            bulkLostTime,
            bulkLostLagTime,
        } = this.props;

        let tabHeaders = selectedCrewLeader ? bulkMovesTabHeadersDrivers : bulkMovesTabHeaders;

        tabHeaders[activeCountIndex].value =
            activeDriverCount && selectedCrewLeader
                ? `${activeDriverCount}`
                : activeCrewCount && !selectedCrewLeader
                ? `${activeCrewCount}`
                : '0';
        tabHeaders[totalBulkMovesIndex].value = totalBulkMoves ? `${totalBulkMoves}` : '0';
        tabHeaders[lagPercentageIndex].value = lagPercentage ? `${lagPercentage}%` : '0%';
        tabHeaders[slowMovePercentageIndex].value = slowMovePercentage ? `${slowMovePercentage}%` : '0%';
        tabHeaders[bulkLostTimeIndex].value = bulkLostTime ? formatTime(bulkLostTime) : '0h 0m';
        tabHeaders[bulkLostLagTimeIndex].value = bulkLostLagTime ? formatTime(bulkLostLagTime) : '0h 0m';

        return (
            <div className={styles.bulkMovesContainer}>
                <div className={styles.bulkMoves}>
                    <div className={styles.filterContainer}>
                        {!selectedCrewLeader && <AuctionDropdown />}
                        {!selectedCrewLeader && (
                            <ButtonGroup>
                                <Button
                                    id="btn-crew-filter"
                                    size="large"
                                    style={{ fontSize: '1.6rem', textTransform: 'capitalize', marginLeft: '0.8rem' }}
                                    variant="outlined"
                                    endIcon={<FilterList fontSize="large" />}
                                    onClick={this.handleOpenCrewMovementsFilter}
                                >
                                    Filter Crews
                                </Button>
                                {finalFilters.length > 0 && (
                                    <Button
                                        id="clear-crew-filter-button"
                                        variant="outlined"
                                        onClick={this.handleClearFilters}
                                    >
                                        <HighlightOff fontSize="large" />
                                    </Button>
                                )}
                            </ButtonGroup>
                        )}
                    </div>
                    <BulkMovesTabContainer
                        selectedTabIndex={selectedTabIndex}
                        onTabChange={this.handleChange}
                        tabs={tabHeaders}
                    />
                    {selectedTabIndex === activeCountIndex && <ActiveCrews />}
                    {selectedTabIndex === totalBulkMovesIndex && <TotalBulkMoves />}
                    {selectedTabIndex === lagPercentageIndex && <LagPercentage />}
                    {selectedTabIndex === slowMovePercentageIndex && <SlowMovePercentage />}
                    {selectedTabIndex === bulkLostTimeIndex && <BulkLostTime />}
                    {selectedTabIndex === bulkLostLagTimeIndex && <BulkLostLagTime />}
                </div>
                <CrewMovementFilterDialog
                    isOpen={openCrewMovementsFilter}
                    closeFilterFunction={this.handleCloseCrewMovementsFilter}
                />
            </div>
        );
    }

    private handleChange = (event: React.ChangeEvent<{}>, value: number): void => {
        const { setSelectedCrewLeaderFn } = this.props;
        this.setState({ selectedTabIndex: value }, () => {
            setSelectedCrewLeaderFn('');
        });
    };

    private handleCloseCrewMovementsFilter = () => {
        this.setState({ openCrewMovementsFilter: false });
    };

    private handleOpenCrewMovementsFilter = () => {
        this.setState({ openCrewMovementsFilter: true });
    };

    private handleLeaveRoom = () => {
        const { userProfile, selectedAuction, bulkFilterStr, wsLeaveRoomFn } = this.props;
        wsLeaveRoomFn({
            auctionCode: selectedAuction,
            user: userProfile.UserName,
            filter: bulkFilterStr,
            mode: 'bulk',
        });
    };

    private handleClearFilters = () => {
        const { setFinalFiltersFn, setDraftFiltersFn, updateBulkFilterStrFn } = this.props;
        try {
            // @ts-ignore
            const { userProfile, selectedAuction, wsJoinRoomFn } = this.props;
            this.handleLeaveRoom();
            wsJoinRoomFn({
                auctionCode: selectedAuction,
                filter: '*ALL*',
                user: userProfile.UserName,
                mode: 'bulk',
            });
        } catch (e) {
            console.error('ERROR switching auction locations. ', e);
        }
        setFinalFiltersFn([]);
        setDraftFiltersFn([]);
        updateBulkFilterStrFn('');
    };
}

const mapStateToProps = (state: any) => ({
    selectedCrewLeader: state.activeCrews.selectedCrewLeader,
    activeCrewCount: state.domain.activeCrews,
    activeDriverCount: state.domain.activeDrivers,
    totalBulkMoves: state.domain.totalBulkMoves,
    lagPercentage: state.domain.lagPercentage,
    slowMovePercentage: state.domain.slowMovePercentage,
    bulkLostTime: state.domain.bulkLostTime,
    bulkLostLagTime: state.domain.bulkLostLagTime,
    userProfile: state.user.profile,
    auctionList: state.user.auctionList,
    selectedAuction: state.user.selectedAuction,
    bulkFilterStr: state.user.bulkFilterStr,
    loading: state.domain.loading,
    crewMovements: state.domain.crewMovements,
    crewLeadersFilterArr: state.domain.crewLeadersFilterArr,
    draftFilters: state.activeCrews.draftFilters,
    finalFilters: state.activeCrews.finalFilters,
});

export const mapDispatchToProps = (dispatch: any) => ({
    setSelectedCrewLeaderFn: (crewLeader: string) => dispatch(setSelectedCrewLeader(crewLeader)),
    setDraftFiltersFn: (filters: string[]) => dispatch(setDraftFilters(filters)),
    setFinalFiltersFn: (filters: string[]) => dispatch(setFinalFilters(filters)),
    updateBulkFilterStrFn: (bulkFilterStr: string) => dispatch(updateBulkFilterStr(bulkFilterStr)),
    wsJoinRoomFn: (socketEvent: SocketEvent) => dispatch(wsJoinRoom(socketEvent)),
    wsLeaveRoomFn: (socketEvent: SocketEvent) => dispatch(wsLeaveRoom(socketEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkMovesProductivity);
