import React from 'react';
import CommonTable from '../../../reusableComponents/CommonTable/CommonTable';
import { LotResultSummaryData } from '../../../global';
import { lotResultsSummaryHeaders } from './headers';
import { compareNumberValues } from '../../../utils/compareUtil';
import { connect } from 'react-redux';
import { updateLotResultsSummaryTableSort } from '../../../redux/activeCrews/actions';

interface OuterProps {
    tableId: string;
    rows: LotResultSummaryData[];
    sortColumn: string;
    sortDirection: string;
    sortFunction: (columnHeader: string) => any;
}

class LotResultsSummaryTable extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public render() {
        const { rows, sortDirection, sortColumn, sortFunction, tableId } = this.props;
        const rowsToSort = [...rows];
        rowsToSort.sort(compareNumberValues(sortColumn, sortDirection));

        return (
            <CommonTable
                id={tableId}
                rows={rowsToSort}
                headers={lotResultsSummaryHeaders}
                sortFunction={sortFunction}
                columnToSort={sortColumn}
                sortDirection={sortDirection}
            />
        );
    }
}

export const mapDispatchToProps = (dispatch: any) => ({
    sortFunction: (columnHeader: string) => dispatch(updateLotResultsSummaryTableSort(columnHeader)),
});

export const mapStateToProps = (state: any) => ({
    rows: state.domain.lotResultsSummary,
    sortColumn: state.activeCrews.lotResultsSummarySortHeader,
    sortDirection: state.activeCrews.sortLotResultsSummaryDescending ? 'desc' : 'asc',
});

export default connect(mapStateToProps, mapDispatchToProps)(LotResultsSummaryTable);
