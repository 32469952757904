import React from 'react';
import { CrewMovementData } from '../../global';
import { connect } from 'react-redux';
import styles from './AlertBar.module.css';

interface OuterProps {
    crewMovements: CrewMovementData[];
    selectedCrewLeader: string;
}

class AlertBar extends React.Component<OuterProps, {}> {
    public constructor(props: any) {
        super(props);
    }

    /**
     *This function creates the count of each inefficiency for the red alert bar that appears
     *at the top of the active crews tab
     *
     * @memberof AlertBar
     */
    countInefficiencies = (data: CrewMovementData[], selectedCrewLeader: string) => {
        var slowMoveCount = 0;
        var mdpCount = 0;
        var ltflCount = 0;
        var lagCount = 0;

        data.forEach(function(item, index) {
            if (selectedCrewLeader && selectedCrewLeader !== item.crewLeader) {
                return;
            }
            if (Boolean(item.slowMoves)) {
                slowMoveCount = slowMoveCount + item.slowMoves;
            }

            if (Boolean(item.lagMoves)) {
                lagCount = lagCount + item.lagMoves;
            }

            if (Boolean(item.totalLTLs)) {
                ltflCount = ltflCount + item.totalLTLs;
            }

            if (Boolean(item.totalMDPs)) {
                mdpCount = mdpCount + item.totalMDPs;
            }
        });

        let inefficiencies = [
            { data: mdpCount, title: 'Multiple Drop-off Points' },
            { data: ltflCount, title: 'Less than Full Loads' },
            { data: slowMoveCount, title: 'Slow Moves' },
            { data: lagCount, title: 'Lag Moves' },
        ];

        return inefficiencies;
    };

    public render() {
        const { crewMovements, selectedCrewLeader } = this.props;
        let inefficiencies = this.countInefficiencies(crewMovements, selectedCrewLeader);
        const alertData = inefficiencies;

        return (
            <div id="inefficiency-bar" className={styles.alertBar} hidden={!alertData.some(alert => alert.data > 0)}>
                <ul>
                    <li className={styles.flagHeader}>Inefficiencies</li>
                    {alertData
                        .sort((a, b) => {
                            return b.data - a.data;
                        })
                        .map((alert, key) => {
                            return alert.data > 0 ? (
                                <li key={key}>
                                    <span className="inefficiency-value">{alert.data}</span>
                                    {alert.title}
                                </li>
                            ) : (
                                false
                            );
                        })}
                </ul>
            </div>
        );
    }
}

export const mapStateToProps = (state: any) => ({
    crewMovements: state.domain.crewMovements,
    selectedCrewLeader: state.activeCrews.selectedCrewLeader,
});

export default connect(mapStateToProps)(AlertBar);
