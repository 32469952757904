import React from 'react';
import { setSelectedCrewLeader } from '../../../redux/activeCrews/actions';
import { connect } from 'react-redux';
import styles from './CrewMovementTable.module.css';
import { SocketEvent } from '../../../global';
import { wsJoinRoom, wsLeaveRoom } from '../../../redux/socket/action';

interface OuterProps {
    name: string;
    active: boolean;
    setSelectedCrewLeaderFn: (crewLeader: string) => void;
    crewLeadersFilterArr: string[];
    finalFilters: string[];
}

class CrewLeaderTableCell extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public render() {
        const { name, active } = this.props;

        return (
            <div
                className={
                    active
                        ? 'crewLeaderTableCellDivActive ' +
                          styles.crewLeaderTableCellDiv +
                          ' ' +
                          styles.crewLeaderTableCellDivActive
                        : 'crewLeaderTableCellDiv ' + styles.crewLeaderTableCellDiv
                }
                onClick={this.handleOnClick}
            >
                {name}
            </div>
        );
    }

    private generateCrewLeaderStr = (filters: string[], crewLeadersFilterArr: string[]) => {
        const updatedArr = crewLeadersFilterArr.filter(crewLeader => !filters.includes(crewLeader));
        return updatedArr.toString();
    };

    private handleOnClick = () => {
        const { setSelectedCrewLeaderFn, name, finalFilters, crewLeadersFilterArr } = this.props;

        setSelectedCrewLeaderFn(name);

        const generatedCrewLeadersArr = this.generateCrewLeaderStr(finalFilters, crewLeadersFilterArr);
        const crewLeadersFilterStr = finalFilters.length > 0 ? generatedCrewLeadersArr.toString() : '*ALL*';

        try {
            // @ts-ignore
            const { userProfile, selectedAuction, wsJoinRoomFn, wsLeaveRoomFn } = this.props;
            wsLeaveRoomFn({
                auctionCode: selectedAuction,
                user: userProfile.UserName,
                filter: crewLeadersFilterStr,
                mode: 'bulk',
            });
            wsJoinRoomFn({
                auctionCode: selectedAuction,
                user: userProfile.UserName,
                filter: name,
                mode: 'bulk',
            });
        } catch (e) {
            console.error('ERROR switching auction locations. ', e);
        }
    };
}

export const mapStateToProps = (state: any) => ({
    userProfile: state.user.profile,
    selectedAuction: state.user.selectedAuction,
    crewLeadersFilterArr: state.domain.crewLeadersFilterArr,
    finalFilters: state.activeCrews.finalFilters,
});

export const mapDispatchToProps = (dispatch: any) => ({
    setSelectedCrewLeaderFn: (crewLeader: string) => dispatch(setSelectedCrewLeader(crewLeader)),
    wsJoinRoomFn: (socketEvent: SocketEvent) => dispatch(wsJoinRoom(socketEvent)),
    wsLeaveRoomFn: (socketEvent: SocketEvent) => dispatch(wsLeaveRoom(socketEvent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrewLeaderTableCell);
