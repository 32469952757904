import React from 'react';
import { CommonTableHeaderData } from '../../global';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortArrow from './TableSortArrow';
import { createStyles, TableHead } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from './CommonTable.module.css';
import FilterIcon from './FilterIcon';

interface OuterProps {
    headers: CommonTableHeaderData[];
    sortFunction: any;
    columnToSort: string;
    sortDirection: string;
    filterFunction?: () => any;
    tableId: string;
}

const useStyles = makeStyles(
    createStyles({
        headerTableCell: {
            background: '#103564',
            color: 'white',
            height: '2.7rem',
            minWidth: '12rem',
            paddingTop: '.625rem',
            paddingBottom: '.625rem',
            paddingLeft: '.2rem',
            paddingRight: '.2rem',
            textAlign: 'center',
            '&:last-child': {
                paddingRight: '0rem',
            },
        },
        tableHead: {
            display: 'table',
            width: '100%',
            tableLayout: 'fixed',
        },
    }),
);

const CommonTableHeader: React.FC<OuterProps> = props => {
    const { headers, sortFunction, sortDirection, columnToSort, filterFunction, tableId } = props;
    const classStyles = useStyles();

    const handleHeaderClick = (columnHeader: string) => {
        sortFunction(columnHeader);
    };

    return (
        <TableHead classes={{ root: classStyles.tableHead }}>
            <TableRow>
                {headers.map((header: CommonTableHeaderData, key: number) => {
                    const sortCurrentColumn = header.key === columnToSort;
                    const headerStyle = header.isSortable
                        ? styles.headerCellDataClickable
                        : styles.headerCellDataUnClickable;
                    return (
                        <TableCell
                            onClick={header.isSortable ? () => handleHeaderClick(header.key) : undefined}
                            classes={{
                                root: classStyles.headerTableCell,
                            }}
                            id={tableId + '-' + header.id}
                            key={key}
                        >
                            <div className={headerStyle}>
                                <div className={styles.headerTextLabel}>{header.displayName}</div>
                                {header.isSortable && (
                                    <TableSortArrow currentlySorting={sortCurrentColumn} direction={sortDirection} />
                                )}
                                {!header.isSortable && header.isFilterable && filterFunction && (
                                    <FilterIcon id={`${header.id}-filter`} openFilter={filterFunction} />
                                )}
                            </div>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default CommonTableHeader;
