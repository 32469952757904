import React from 'react';
import styles from './App.module.css';
import AppTabContainer from './AppTabContainer';
import { appTabHeaders, appTabHeadersProd } from '../utils/headers';
import { connect } from 'react-redux';
import UserService from '../services/UserService';
import { parse } from 'query-string';
import { fetchUserDataFn, updateSelectedDashboard } from '../redux/user/action';
import { setSelectedCrewLeader } from '../redux/activeCrews/actions';
import { Dispatch } from 'redux';
import { AuctionDetails, UserProfile } from '../global';
import BulkMovesProductivity from './bulkMovesProductivity/BulkMovesProductivity';
import SaleMovesProductivity from './saleMovesProductivity/SaleMovesProductivity';
import { rtrBackendUrl } from '../config/config';
interface AppState {
    selectedTabIndex: number;
}

interface OuterProps {
    fetchUserFn: (token: string) => Dispatch<any>;
    updateSelectedDashboardFn: (selectedDashboard: string) => Dispatch<any>;
    setSelectedCrewLeaderFn: (crewLeader: string) => void;
    userProfile: UserProfile;
    auctionList: AuctionDetails[];
    selectedAuction: string;
    selectedDashboard: string;
    loading: boolean;
}

const bulkMovesIndex = 0;
const saleMovesIndex = 1;

class App extends React.Component<OuterProps, AppState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
        };
    }

    /**
     *In this lifecycle function we check for an auth token in local storage and if we have one we
     *verify the token. If not we initiate OAM login. Then we setup the socket connection for the
     *current auction.
     *
     * @returns {Promise<void>}
     * @memberof App
     */
    public async componentDidMount(): Promise<void> {
        const { fetchUserFn, updateSelectedDashboardFn } = this.props;
        const existingToken = window.localStorage.getItem('auth_token');
        const params = parse(window.location.search);
        const newToken = params.auth_token ? String(params.auth_token) : undefined;
        updateSelectedDashboardFn('bulk');

        if (!newToken && !existingToken) {
            await UserService.login();
        }
        if (newToken) {
            await fetchUserFn(newToken);
        }
        if (existingToken) {
            await fetchUserFn(existingToken);
        }
    }

    /**
     *In this lifecycle function we leave the current room on the socket connection
     *if the user is logged in
     *
     * @memberof App
     */
    public render() {
        return (
            <div className={styles.appContainer}>
                {this.props.userProfile && this.props.auctionList && this.props.selectedAuction && this.content()}
                <div id="loadingIndicator" className={styles.loading} hidden={!this.props.loading}></div>
            </div>
        );
    }

    private content() {
        const isNotProd =
            rtrBackendUrl.includes('uat') || rtrBackendUrl.includes('qa') || rtrBackendUrl.includes('localhost');
        const { selectedTabIndex } = this.state;

        return (
            <div>
                {isNotProd && (
                    <AppTabContainer
                        selectedTabIndex={selectedTabIndex}
                        onTabChange={this.handleChange}
                        tabs={appTabHeaders}
                    />
                )}
                {!isNotProd && (
                    <AppTabContainer
                        selectedTabIndex={selectedTabIndex}
                        onTabChange={this.handleChange}
                        tabs={appTabHeadersProd}
                    />
                )}
                {selectedTabIndex === bulkMovesIndex && <BulkMovesProductivity />}
                {selectedTabIndex === saleMovesIndex && <SaleMovesProductivity />}
            </div>
        );
    }

    private handleChange = (event: React.ChangeEvent<{}>, value: number): void => {
        const { setSelectedCrewLeaderFn, updateSelectedDashboardFn } = this.props;
        this.setState({ selectedTabIndex: value }, () => {
            setSelectedCrewLeaderFn('');
            value === 1 ? updateSelectedDashboardFn('sale') : updateSelectedDashboardFn('bulk');
        });
    };
}

const mapStateToProps = (state: any) => ({
    userProfile: state.user.profile,
    auctionList: state.user.auctionList,
    selectedAuction: state.user.selectedAuction,
    selectedDashboard: state.user.selectedDashboard,
    loading: state.domain.loading,
});

export const mapDispatchToProps = (dispatch: any) => ({
    fetchUserFn: (token: string) => dispatch(fetchUserDataFn(token)),
    updateSelectedDashboardFn: (selectedDashboard: string) => dispatch(updateSelectedDashboard(selectedDashboard)),
    setSelectedCrewLeaderFn: (crewLeader: string) => dispatch(setSelectedCrewLeader(crewLeader)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
