import React from 'react';
import Tab from '@material-ui/core/Tab';
import BulkMoveTabLabel from '../../reusableComponents/BulkMoveTabLabel/BulkMoveTabLabel';
import { styled } from '@material-ui/core/styles';
import { BulkMoveTabLabelData } from '../../global';

interface BulkMovesTabProps {
    data: BulkMoveTabLabelData;
    onTabChange: (event: React.ChangeEvent<{}>, value: number) => void;
}

const StyledTab = styled(Tab)({
    background: '#FFFFFF',
    width: '15rem',
    wordWrap: 'normal',
    border: 'none',
    opacity: 1,
    color: '#005ba8',
});

const BulkMovesTab: React.FC<BulkMovesTabProps> = props => {
    const { data, onTabChange } = props;

    return (
        <StyledTab
            style={{ opacity: 1 }}
            disabled={data.tabValue >= 0}
            id={data.id}
            value={data.tabValue}
            onChange={onTabChange}
            label={<BulkMoveTabLabel data={data} />}
        />
    );
};

export default BulkMovesTab;
