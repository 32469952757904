import {
    ADD_DRAFT_FILTER,
    CLEAR_SELECTED_CREW_LEADER,
    REMOVE_DRAFT_FILTER,
    SET_ACTIVE_CREWS_CREW_MOVEMENTS_DEFAULT_SORT,
    SET_DRIVER_MOVEMENTS_DEFAULT_SORT,
    SET_ACTIVE_CREWS_DRIVER_METRICS_DEFAULT_SORT,
    SET_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_DEFAULT_SORT,
    SET_DRAFT_FILTERS,
    SET_FINAL_FILTERS,
    SET_SELECTED_CREW_LEADER,
    UPDATE_ACTIVE_CREWS_CREW_MOVEMENTS_TABLE_SORT,
    UPDATE_ACTIVE_CREWS_DRIVER_METRICS_TABLE_SORT,
    UPDATE_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_TABLE_SORT,
    UPDATE_DRIVER_MOVEMENTS_TABLE_SORT,
} from './constants';

const crewMovementsInitialState = {
    crewMovementsSortHeader: 'activeLeader',
    sortCrewMovementsDescending: true,
};

const lotResultsSummaryInitialState = {
    lotResultsSummarySortHeader: 'issuesFlagged',
    sortLotResultsSummaryDescending: true,
};

const driverMetricsInitialState = {
    driverMetricsSortHeader: 'longestReturn',
    sortDriverMetricsDescending: true,
};

const driverMovementsInitialState = {
    driverMovementsSortHeader: 'assignedTime',
    sortDriverMovementsDescending: true,
};

const initialState = {
    ...crewMovementsInitialState,
    ...lotResultsSummaryInitialState,
    ...driverMetricsInitialState,
    ...driverMovementsInitialState,
    draftFilters: [],
    finalFilters: [],
    selectedCrewLeader: null,
};

export default function activeCrews(state: any = initialState, action: any) {
    switch (action.type) {
        case UPDATE_ACTIVE_CREWS_CREW_MOVEMENTS_TABLE_SORT:
            return {
                ...state,
                crewMovementsSortHeader: action.tableHeader,
                sortCrewMovementsDescending:
                    action.tableHeader === state.crewMovementsSortHeader ? !state.sortCrewMovementsDescending : true,
            };
        case SET_ACTIVE_CREWS_CREW_MOVEMENTS_DEFAULT_SORT:
            return {
                ...state,
                ...crewMovementsInitialState,
            };
        case UPDATE_DRIVER_MOVEMENTS_TABLE_SORT:
            return {
                ...state,
                driverMovementsSortHeader: action.tableHeader,
                sortDriverMovementsDescending:
                    action.tableHeader === state.driverMovementsSortHeader
                        ? !state.sortDriverMovementsDescending
                        : true,
            };
        case SET_DRIVER_MOVEMENTS_DEFAULT_SORT:
            return {
                ...state,
                ...driverMovementsInitialState,
            };
        case UPDATE_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_TABLE_SORT:
            return {
                ...state,
                lotResultsSummarySortHeader: action.tableHeader,
                sortLotResultsSummaryDescending:
                    action.tableHeader === state.lotResultsSummarySortHeader
                        ? !state.sortLotResultsSummaryDescending
                        : true,
            };
        case SET_ACTIVE_CREWS_LOT_RESULTS_SUMMARY_DEFAULT_SORT:
            return {
                ...state,
                ...lotResultsSummaryInitialState,
            };
        case UPDATE_ACTIVE_CREWS_DRIVER_METRICS_TABLE_SORT:
            return {
                ...state,
                driverMetricsSortHeader: action.tableHeader,
                sortDriverMetricsDescending:
                    action.tableHeader === state.driverMetricsSortHeader ? !state.sortDriverMetricsDescending : true,
            };
        case SET_ACTIVE_CREWS_DRIVER_METRICS_DEFAULT_SORT:
            return {
                ...state,
                ...driverMetricsInitialState,
            };
        case SET_DRAFT_FILTERS:
            return {
                ...state,
                draftFilters: action.filters,
            };
        case SET_FINAL_FILTERS:
            return {
                ...state,
                finalFilters: action.filters,
            };
        case ADD_DRAFT_FILTER: {
            const arrayToReturn = [...state.draftFilters];
            arrayToReturn.push(action.filter);
            return {
                ...state,
                draftFilters: arrayToReturn,
            };
        }
        case REMOVE_DRAFT_FILTER: {
            const arrayToReturn = [...state.draftFilters];
            const elementIndex = arrayToReturn.indexOf(action.filter);
            arrayToReturn.splice(elementIndex, 1);
            return {
                ...state,
                draftFilters: arrayToReturn,
            };
        }
        case SET_SELECTED_CREW_LEADER:
            return {
                ...state,
                selectedCrewLeader: action.crewLeader,
            };
        case CLEAR_SELECTED_CREW_LEADER:
            return {
                ...state,
                selectedCrewLeader: null,
            };
        default:
            return state;
    }
}
