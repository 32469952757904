import React from 'react';
import styles from '../../../reusableComponents/DataTables/DataTables.module.css';
import DriverMovementTable from '../../../reusableComponents/DataTables/DriverMovementTable/DriverMovementTable';
import LotResultsSummaryTable from '../../../reusableComponents/DataTables/LotResultsSummaryTable/LotResultsSummaryTable';

interface OuterProps {}

const BulkLostLagTimeView: React.FC<OuterProps> = (props: OuterProps) => {
    return (
        <div>
            <div className={styles.crewMovementsContainer}>
                <div className={styles.titleContainer}>
                    <div id="completed-bulk-moves-table-title" className={styles.tableTitle}>
                        Completed Bulk Moves with Lost Lag Time
                    </div>
                </div>
                <DriverMovementTable tableId="completed-bulk-moves" />
            </div>
            <div>
                <div className={styles.titleContainer}>
                    <div id="lot-results-summary-table-title" className={styles.tableTitle}>
                        Lot Results Summary
                    </div>
                </div>
                <LotResultsSummaryTable tableId="lot-results-summary" />
            </div>
        </div>
    );
};

export default BulkLostLagTimeView;
