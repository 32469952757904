import React from 'react';
import CommonTable from '../../../reusableComponents/CommonTable/CommonTable';
import { AssignedTime, CrewAssignedTimes } from '../../../global';
import { driverMovementTableHeaders, commonMovementTableHeaders } from './headers';
import { compareMovementValues, compareNumberValues } from '../../../utils/compareUtil';
import { connect } from 'react-redux';
import { updateDriverMovementTableSort } from '../../../redux/activeCrews/actions';

interface OuterProps {
    tableId: string;
    crewAssignedTimes: CrewAssignedTimes[];
    sortColumn: string;
    sortDirection: string;
    sortFunction: (columnHeader: string) => any;
}

class DriverMovementTable extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    generateMovements = (data: any[]) => {
        var movements: AssignedTime[] = [];

        let crewAssignedTimes: CrewAssignedTimes[] = data;
        if (crewAssignedTimes) {
            Array.from(crewAssignedTimes).forEach(crewAssignedTime => {
                let assignedTimeArray = crewAssignedTime.assignedTimesArray;
                assignedTimeArray.forEach(assignedTime => {
                    if (assignedTime.assignedTime) {
                        movements.push(assignedTime);
                    }
                });
            });
        }

        return movements;
    };

    public render() {
        const { crewAssignedTimes, sortDirection, sortColumn, sortFunction, tableId } = this.props;

        var rows: AssignedTime[] = this.generateMovements(crewAssignedTimes);
        rows.sort(
            sortColumn === 'assignedTime'
                ? compareNumberValues(sortColumn, sortDirection)
                : compareMovementValues(sortColumn, sortDirection),
        );

        return (
            <CommonTable
                id={tableId}
                rows={rows}
                headers={tableId === 'driver-movements' ? driverMovementTableHeaders : commonMovementTableHeaders}
                sortFunction={sortFunction}
                columnToSort={sortColumn}
                sortDirection={sortDirection}
            />
        );
    }
}

export const mapDispatchToProps = (dispatch: any) => ({
    sortFunction: (columnHeader: string) => dispatch(updateDriverMovementTableSort(columnHeader)),
});

export const mapStateToProps = (state: any) => ({
    crewAssignedTimes: state.domain.crewLeaderCrewMoves[state.activeCrews.selectedCrewLeader],
    sortColumn: state.activeCrews.driverMovementsSortHeader,
    sortDirection: state.activeCrews.sortDriverMovementsDescending ? 'desc' : 'asc',
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverMovementTable);
