import React from 'react';
import CommonTable from '../../../reusableComponents/CommonTable/CommonTable';
import { driverMetricsCurrentCycleTableHeaders, driverMetricsDailySummaryTableHeaders } from './headers';
import { CrewAssignedTimes, DriverMetricsData } from '../../../global';
import { updateDriverMetricsTableSort } from '../../../redux/activeCrews/actions';
import { connect } from 'react-redux';
import { compareNumberValues } from '../../../utils/compareUtil';
import styles from './DriverMetricsTable.module.css';

interface OuterProps {
    rows: DriverMetricsData[];
    crewAssignedTimes: CrewAssignedTimes[];
    crewLeaderCrewMoves: { [key: string]: CrewAssignedTimes[] };
    sortColumn: string;
    sortDirection: string;
    sortFunction: (columnHeader: string) => any;
    crewLeader: string;
}

class DriverMetricsTable extends React.Component<OuterProps, {}> {
    private currentCycleTableRef: any;
    private dailySummaryTableRef: any;
    public constructor(props: OuterProps) {
        super(props);
        this.currentCycleTableRef = React.createRef();
        this.dailySummaryTableRef = React.createRef();
    }

    public render() {
        const { rows, sortDirection, sortColumn, sortFunction } = this.props;

        const generatedRows = rows ? [...rows] : [];

        generatedRows.sort(compareNumberValues(sortColumn, sortDirection));

        return (
            <div id={'driver-metrics-table'} className={styles.tableContainer}>
                <div style={{ width: '47%' }}>
                    <div id={'driver-metrics-current-cycle-title'} className={styles.tableTitle}>
                        Current Cycle
                    </div>
                    <CommonTable
                        id={'driver-metrics-current-cycle'}
                        rows={generatedRows}
                        headers={driverMetricsCurrentCycleTableHeaders}
                        sortFunction={sortFunction}
                        columnToSort={sortColumn}
                        sortDirection={sortDirection}
                        isScrollBarHidden={true}
                        styles={{ borderRight: '1px solid #babcbe' }}
                        innerRef={this.currentCycleTableRef}
                        scrollFn={this.handleCurrentCycleScroll}
                    />
                </div>
                <div style={{ width: '53%' }}>
                    <div id={'driver-metrics-daily-summary-title'} className={styles.tableTitle}>
                        Daily Summary
                    </div>
                    <CommonTable
                        id={'driver-metrics-daily-summary'}
                        rows={generatedRows}
                        headers={driverMetricsDailySummaryTableHeaders}
                        sortFunction={sortFunction}
                        columnToSort={sortColumn}
                        sortDirection={sortDirection}
                        innerRef={this.dailySummaryTableRef}
                        scrollFn={this.handleDailySummaryScroll}
                    />
                </div>
            </div>
        );
    }

    private handleCurrentCycleScroll = () => {
        this.dailySummaryTableRef.current.scrollTop = this.currentCycleTableRef.current.scrollTop;
    };

    private handleDailySummaryScroll = () => {
        this.currentCycleTableRef.current.scrollTop = this.dailySummaryTableRef.current.scrollTop;
    };
}

export const mapDispatchToProps = (dispatch: any) => ({
    sortFunction: (columnHeader: string) => dispatch(updateDriverMetricsTableSort(columnHeader)),
});

export const mapStateToProps = (state: any) => ({
    rows: state.domain.driverMetrics[state.activeCrews.selectedCrewLeader],
    crewAssignedTimes: state.domain.crewLeaderCrewMoves[state.activeCrews.selectedCrewLeader],
    sortColumn: state.activeCrews.driverMetricsSortHeader,
    sortDirection: state.activeCrews.sortDriverMetricsDescending ? 'desc' : 'asc',
    crewLeaderCrewMoves: state.domain.crewLeaderCrewMoves,
    crewLeader: state.activeCrews.selectedCrewLeader,
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverMetricsTable);
