import { WS_JOIN_ROOM, WS_LEAVE_ROOM, WsActionTypes } from './constants';
import { SocketEvent } from '../../global';

export function wsJoinRoom(socketEvent: SocketEvent): WsActionTypes {
    return {
        type: WS_JOIN_ROOM,
        socketEvent,
    };
}

export function wsLeaveRoom(socketEvent: SocketEvent): WsActionTypes {
    return {
        type: WS_LEAVE_ROOM,
        socketEvent,
    };
}
