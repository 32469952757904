import React from 'react';
import styles from './AppTabLabel.module.css';
import { AppTabLabelData } from '../../global';

interface AppTabLabelProps {
    data: AppTabLabelData;
}
const AppTabLabel: React.FC<AppTabLabelProps> = props => {
    const { data } = props;
    return (
        <div>
            <div id={`${data.id}-label`} className={styles.appTabLabelTitle}>
                {data.label}
            </div>
        </div>
    );
};

export default AppTabLabel;
