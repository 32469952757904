import {
    FETCH_USER_DATA_SUCCESS,
    FETCH_USER_DATA,
    FETCH_USER_DATA_FAILURE,
    CREATE_CREWS_FILTER_ARR,
    CREATE_AUCTION_LIST,
    UPDATE_SELECTED_AUCTION,
    UPDATE_SELECTED_DASHBOARD,
    UPDATE_BULK_FILTER_STR,
} from './constants';

const initialState = {
    bulkFilterStr: '',
};

export default function user(state: any = initialState, action: any) {
    switch (action.type) {
        case FETCH_USER_DATA_SUCCESS:
            return {
                ...state,
                profile: action.userProfile,
            };
        case CREATE_CREWS_FILTER_ARR:
            return {
                ...state,
                crewsFilterArr: action.filteredCrewsArr,
            };
        case CREATE_AUCTION_LIST:
            return {
                ...state,
                auctionList: action.sortedFilteredAuctions,
            };
        case UPDATE_SELECTED_AUCTION:
            return {
                ...state,
                selectedAuction: action.selectedAuction,
            };
        case UPDATE_SELECTED_DASHBOARD:
            return {
                ...state,
                selectedDashboard: action.selectedDashboard,
            };
        case UPDATE_BULK_FILTER_STR:
            return {
                ...state,
                bulkFilterStr: action.bulkFilterStr,
            };
        case FETCH_USER_DATA:
        case FETCH_USER_DATA_FAILURE:
        default:
            return state;
    }
}
