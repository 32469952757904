import {
    generateGenericCellData,
    generateTimeCellData,
    generateDriverTableCell,
    generateLotOriginData,
} from '../../../reusableComponents/CellTransformers/cellTransformers';
import { CommonTableHeaderData } from '../../../global';

export const driverMetricsCurrentCycleTableHeaders: CommonTableHeaderData[] = [
    {
        id: 'driver-header',
        key: 'activeDriver',
        displayName: 'Driver',
        transform: generateDriverTableCell,
        isSortable: false,
    },
    {
        id: 'lot-origin-header',
        key: 'lotInfo',
        displayName: 'Lot Origin',
        transform: generateLotOriginData,
        isSortable: false,
    },
    {
        id: 'ongoing-delivery-header',
        key: 'ongoingDelivery',
        displayName: 'Ongoing Delivery',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'ongoing-return-header',
        key: 'ongoingReturn',
        displayName: 'Ongoing Return',
        transform: generateTimeCellData,
        isSortable: true,
    },
];

export const driverMetricsDailySummaryTableHeaders: CommonTableHeaderData[] = [
    {
        id: 'bulk-lost-time-header',
        key: 'bulkLostTime',
        displayName: 'Bulk Lost Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'bulk-lost-lag-time-header',
        key: 'bulkLostLagTime',
        displayName: 'Bulk Lost Lag Time',
        transform: generateTimeCellData,
        isSortable: true,
    },
    {
        id: 'less-than-two-minute-header',
        key: 'lessThanTwoMinuteMoves',
        displayName: '< 2 Minute Moves',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'total-number-of-times-unassigned-header',
        key: 'numberOfTimesUnassigned',
        displayName: 'Total # Times Unassigned',
        transform: generateGenericCellData,
        isSortable: true,
    },
    {
        id: 'total-moves-header',
        key: 'totalMoves',
        displayName: 'Total Moves',
        transform: generateGenericCellData,
        isSortable: true,
    },
];
