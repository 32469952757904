import React from 'react';
import CommonTable from '../../../reusableComponents/CommonTable/CommonTable';
import { CrewMovementData, CrewAssignedTimes } from '../../../global';
import { crewMovementsCurrentCycleTableHeaders, crewMovementsDailySummaryTableHeaders } from './headers';
import { compareArrayValues, compareNumberValues, compareActiveValues } from '../../../utils/compareUtil';
import { updateCrewMovementTableSort } from '../../../redux/activeCrews/actions';
import { connect } from 'react-redux';
import styles from './CrewMovementTable.module.css';

interface OuterProps {
    rows: CrewMovementData[];
    crewLeaderCrewMoves: { [key: string]: CrewAssignedTimes[] };
    sortColumn: string;
    sortDirection: string;
    sortFunction: (columnHeader: string) => any;
    finalFilters: string[];
}

class CrewMovementTable extends React.Component<OuterProps> {
    private currentCycleTableRef: any;
    private dailySummaryTableRef: any;

    public constructor(props: OuterProps) {
        super(props);
        this.currentCycleTableRef = React.createRef();
        this.dailySummaryTableRef = React.createRef();
    }

    public render() {
        const { rows, sortDirection, sortColumn, sortFunction, finalFilters } = this.props;

        const generatedRows = rows ? [...rows] : [];
        generatedRows.sort(
            sortColumn === 'issuesFlagged'
                ? compareArrayValues(sortColumn, sortDirection)
                : sortColumn === 'activeLeader'
                ? compareActiveValues(sortColumn, sortDirection)
                : compareNumberValues(sortColumn, sortDirection),
        );

        const filteredRows = generatedRows.filter(crewMovement => {
            return !finalFilters.includes(crewMovement.crewLeader);
        });

        return (
            <div>
                <div id={'crew-movements-table'} className={styles.crewMovementsTableContainer}>
                    <div style={{ width: '47%' }}>
                        <div id={'crew-movements-current-cycle-title'} className={styles.tableTitle}>
                            Current Cycle
                        </div>
                        <CommonTable
                            id="crew-movements-current-cycle"
                            rows={filteredRows}
                            headers={crewMovementsCurrentCycleTableHeaders}
                            sortFunction={sortFunction}
                            columnToSort={sortColumn}
                            sortDirection={sortDirection}
                            styles={{ borderRight: '1px solid #babcbe' }}
                            innerRef={this.currentCycleTableRef}
                            isScrollBarHidden={true}
                            scrollFn={this.handleCurrentCycleScroll}
                        />
                    </div>
                    <div style={{ width: '53%' }}>
                        <div id={'crew-movements-daily-summary-title'} className={styles.tableTitle}>
                            Daily Summary
                        </div>
                        <CommonTable
                            id="crew-movements-daily-summary"
                            rows={filteredRows}
                            headers={crewMovementsDailySummaryTableHeaders}
                            sortFunction={sortFunction}
                            columnToSort={sortColumn}
                            sortDirection={sortDirection}
                            innerRef={this.dailySummaryTableRef}
                            scrollFn={this.handleDailySummaryScroll}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private handleCurrentCycleScroll = () => {
        this.dailySummaryTableRef.current.scrollTop = this.currentCycleTableRef.current.scrollTop;
    };

    private handleDailySummaryScroll = () => {
        this.currentCycleTableRef.current.scrollTop = this.dailySummaryTableRef.current.scrollTop;
    };
}

export const mapDispatchToProps = (dispatch: any) => ({
    sortFunction: (columnHeader: string) => dispatch(updateCrewMovementTableSort(columnHeader)),
});

export const mapStateToProps = (state: any) => ({
    finalFilters: state.activeCrews.finalFilters,
    rows: state.domain.crewMovements,
    sortColumn: state.activeCrews.crewMovementsSortHeader,
    sortDirection: state.activeCrews.sortCrewMovementsDescending ? 'desc' : 'asc',
    crewLeaderCrewMoves: state.domain.crewLeaderCrewMoves,
});

export default connect(mapStateToProps, mapDispatchToProps)(CrewMovementTable);
