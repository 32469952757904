import React from 'react';
import Tab from '@material-ui/core/Tab';
import AppTabLabel from '../reusableComponents/AppTabLabel/AppTabLabel';
import { AppTabLabelData } from '../global';
import withStyles from '@material-ui/core/styles/withStyles';

interface AppTabProps {
    data: AppTabLabelData;
    onTabChange: (event: React.ChangeEvent<{}>, value: number) => void;
}
const StyledTab = withStyles({
    root: {
        backgroundColor: '#555',
        minWidth: '280px',
    },
})(Tab);

const AppTab: React.FC<AppTabProps> = props => {
    const { data, onTabChange } = props;

    return (
        <StyledTab
            style={{ opacity: 1 }}
            disabled={data.tabValue > 1}
            id={data.id}
            value={data.tabValue}
            onChange={onTabChange}
            label={<AppTabLabel data={data} />}
        />
    );
};

export default AppTab;
