import React from 'react';
import { UserProfile } from '../../global';
import { connect } from 'react-redux';
import CrewLeadersView from './CrewLeadersView/CrewLeadersView';
import styles from './ActiveCrews.module.css';
import DriverMetricsView from './DriverMetricsView/DriverMetricsView';

interface OuterProps {
    selectedCrewLeader: string;
    selectedAuction: string;
    userProfile: UserProfile;
}

class ActiveCrews extends React.Component<OuterProps, {}> {
    public constructor(props: OuterProps) {
        super(props);
    }

    public componentDidMount(): void {}

    public componentWillUnmount(): void {}

    public render() {
        const { selectedCrewLeader } = this.props;
        return (
            <div id="active-crews-container" className={styles.container}>
                {selectedCrewLeader ? <DriverMetricsView /> : <CrewLeadersView />}
            </div>
        );
    }
}

export const mapDispatchToProps = (dispatch: any) => ({});

export const mapStateToProps = (state: any) => ({
    selectedCrewLeader: state.activeCrews.selectedCrewLeader,
    selectedAuction: state.user.selectedAuction,
    userProfile: state.user.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCrews);
